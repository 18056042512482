import { jsonParsed } from 'common/utilities/utils';
import parse from 'html-react-parser';
import { COUPON_STATIC_MSG } from '../Constants/CouponConstants';
import {
  getUnixTimeInSeconds,
  getCurrentDateTime,
  getTimeDifference,
  formatDateTime,
  getFormattedFromUnixWithTimeZone,
} from 'common/utilities/dayJsUtils';
import { SHARED_CONSTANTS, DATE_FORMATS } from 'common/constants/SharedConstants';
const getKey = key => {
  return `${String(key).substr(0, 1).toLowerCase()}${String(key).substr(1)}`;
};

export const jsontoObjects = obj => {
  return (
    obj?.length &&
    obj.reduce(
      (acc, { key, value }) =>
        (acc = {
          ...acc,
          [getKey(key)]:
            String(value).toUpperCase() === 'TRUE'
              ? true
              : String(value).toUpperCase() === 'FALSE'
              ? false
              : jsonParsed(value),
        }),
      {},
    )
  );
};

/**
 * Converts API Response into mappable Array of objects
 * @param {Array} data
 * @returns {Array}
 */
export const modifyData = data =>
  data.map(coupon => ({
    discountCode: coupon?.code,
    heading:
      coupon?.promotionDetails?.title &&
      coupon?.promotionDetails?.title.length &&
      getMarkedHeading(coupon?.promotionDetails?.title[0]?.value),
    description:
      coupon?.promotionDetails?.description &&
      coupon?.promotionDetails?.description.length &&
      coupon?.promotionDetails?.description[0]?.value,
    imageSrc:
      coupon?.promotionDetails?.image &&
      coupon?.promotionDetails?.image.length &&
      coupon?.promotionDetails?.image[0]?.value,
    endTime: coupon?.endDateTime,
    termsAndConditions: getConditions(coupon?.promotionDetails?.conditions),
    isApplicable: coupon?.isApplicable,
    isApplied: coupon?.isApplied,
    isRewards: coupon?.isRewards,
    cardType: coupon?.promotionDetails?.cardType,
    ...jsontoObjects(coupon?.promotionDetails?.customProperties),
  }));

/**
 * Converts API Response into mappable Array of objects
 * @param {Array} data
 * @returns {Array}
 */
export const modifyOffers = data => {
  return {
    discountCode: data?.couponCode,
    heading: data?.titleValue,
    description: data?.smallText,
    endTime: data?.validityEndTime,
    isApplied: data?.isApplied,
  };
};

/**
 * converts strings with html elemnts into react elements
 * @param {String} heading
 * @returns {JSX.Element} JSX Element
 */
export const getMarkedHeading = (heading = '') => {
  const alteredHeading = heading?.replace('<strikethrough>', '<del>').replace('</strikethrough>', '</del>');
  return parse(`${alteredHeading}`);
};

/**
 * Converts Terms and Conditions array into mappable Array of Objects
 * @param {Array} conditions
 * @returns {Array}
 */
export const getConditions = conditions => {
  return conditions
    ?.map(msg => ({
      displayOrder: msg.displayOrder,
      message: msg.displayMessage[0].value,
    }))
    .sort((a, b) => +a.displayOrder - +b.displayOrder);
};

/**
 *
 * @param {number} time
 * @returns {boolean} isPastTime
 */
export const isTimeValid = time => {
  if (time) {
    const endTime = getUnixTimeInSeconds(time);
    const currTime = getCurrentDateTime();
    const secDiff = getTimeDifference(endTime, currTime, 'seconds');
    const isPastTime = String(secDiff).includes('-');
    return endTime.isValid() && !isPastTime;
  } else {
    return false;
  }
};

/**
 * Method to find the time to expiry of coupon and
 * creating time string in format ({HH}HR : {MM}M : {SS}S)
 * @param {Number} time - Time in Unix format
 * @returns {String}
 */
export const getTimeString = time => {
  const endTime = getUnixTimeInSeconds(time);
  const currTime = getCurrentDateTime();
  const hourDiff = getTimeDifference(endTime, currTime, 'hour');
  const addedHour = currTime.add(hourDiff, 'hours');
  const minDiff = getTimeDifference(endTime, addedHour, 'minute');
  const addedMinute = addedHour.add(minDiff, 'minutes');
  const secDiff = getTimeDifference(endTime, addedMinute, 'second');
  return `${hourDiff}HR : ${minDiff}M : ${secDiff}S`;
};

/**
 * Method to get valid end date in dd/mm/yy format
 */
export const getValidFormatDate = time => {
  const endTime = getUnixTimeInSeconds(time);
  return formatDateTime(endTime, 'DD/MM/YY');
};

/**
 * Method to get valid end date in dd/mm/yyyy format
 */
export const getValidFormatDatePerYear = time => {
  if (!time) {
    return time;
  }
  const endTime = getUnixTimeInSeconds(time);
  return formatDateTime(endTime, 'DD/MM/YYYY');
};

export const getErrorMsg = discountLine => {
  const errorMsg = discountLine?.promoConditionStatus.filter(obj => !obj?.isValidCondition);
  if (discountLine?.basicErrors?.length) {
    return COUPON_STATIC_MSG + discountLine?.basicErrors[0]?.message;
  } else if (errorMsg?.length) {
    return COUPON_STATIC_MSG + errorMsg[0]?.displayMessage[0]?.value;
  }
};

export const sanitizeContent = content => {
  let div = document.createElement('div');
  div.innerHTML = content;
  return div.innerText || '';
};

//  Method to get end date for coupon from epoch time
export const getCouponDate = time => {
  return getFormattedFromUnixWithTimeZone(time, DATE_FORMATS.UTC, SHARED_CONSTANTS.DD_MM_YY_WITH_SLASH);
};

/**
 *
 * @param {*} data entire content ful data
 * @param {*} key to key the data out of full content ful data
 */
import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import events from '@kfc-global/react-shared/analytics/Register';
import Strings from '@kfc-global/react-shared/constants/Strings';
import {
  APP_DEVELOPMENT,
  TENANT_IMAGE_BASE_URL,
  TENANT_IMAGE_PATH,
  MAP_DIRECTION_BASE_URL,
  STORE_CLOSED_MODAL_TIMING,
  STORE_CLOSING_SOON_MODAL_TIMING,
} from '@kfc-global/react-shared/config/config.constants';
import DayJsUtil from '@kfc-global/react-shared/utils/DayJSUtils';
import geoLocation from '@kfc-global/react-shared/utils/geoLocation.js';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { pathNameIncludesTrue } from 'organisms/NutritionAllergenLandingPage/AU/NutritionAllergenUtilities/NutritionAllergenUtils';
import { initI18N, translateWithI18Next, getLanguage } from '@kfc-global/kfc-i18n/lib';
import { parseAddress } from '@kfc-global/react-shared/utils/AddressParser.js';
import { ChannelManager } from 'common/manager';
import HOME_PAGE_CONTAINER_CONSTANTS from 'components/homePageContainer/Constants/AU/homePageContainerConstants';
import get from 'lodash/get';
import _first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CART_PAGE_CONSTANTS } from '../../organisms/CartPage/Constants/CartPageConstants';
import { DRIVER_TIPPING } from '../../organisms/CheckoutDeliveryTipping/constants/DeliveryTippingConstants';
import FOOTER_CONSTANT from '../../organisms/FooterComponent/FooterConstants/FooterConstant';
import {
  AMOUNT_VALIDATION_ERROR_CODES,
  BLACKLISTED_COUPONS,
  Config,
  CURRENTSTOREINFO,
  DEFAULT_IMAGE_PATH,
  DELIVERY,
  DELIVERY_TEXT,
  DINE_IN,
  CATERING,
  CHRISTMAS_ORDERING,
  CHRISTMAS_ORDERING_TEXT,
  CLOSED,
  STORE_CLOSED,
  STORE_OPEN,
  TECHNICAL_SHUTDOWN,
  HOLIDAY_CLOSED,
  HOLIDAY_OPEN,
  DISPOSITIONNEW,
  DISPOSITIONOLD,
  OPEN,
  HOME_PAGE_CONTENT_DATAKEY,
  IPAD_CHECK_WIDTH,
  PICK_UP,
  ROUTE_URL,
  SOURCE_FLAG_DESKTOP,
  SOURCE_FLAG_MOBILE,
  TAKEAWAY_TEXT,
  TYPE_DELIVERY,
  TYPE_DINE_IN,
  TYPE_TAKE_WAY,
  TYPE_CATERING,
  TYPE_CHRISTMAS_ORDERING,
  CARTROUTE,
  ToastMSG,
  ToastMSG_Items,
  COUNTRY_CODE,
  BRAND_PAGE_KEYS,
  DOWNLOAD_PAGE_KEYS,
  LOCAL_STORAGE_KEYS,
  DAYS,
  availableFrom,
  availableTo,
  TYPE_TAKEAWAY_TEXT,
  FIELDS,
  TITLE,
  CONTENT,
  TOPICS,
  LINKTEXT,
  INTERNALURL,
  DESCRIPTION,
  HEADLINE,
  NAME,
  BUTTONTEXT,
  IMAGE,
  FILE,
  URL,
  MOBILEIMG,
  DESKTOPIMG,
  DAYS_OBJ,
  ICON,
  PHNUMBER,
  CONTACTUS,
  GETHELP,
  NEARESTKFC,
  CALLUS,
  FAQ,
  SHAREEXPERIENCE,
  FAQTOPICS,
  VIEWALLFAQ,
  DISPOSITION_KEYS,
  EVERYDAY,
  EMAIL_REGEX,
  FULL_NAME_REGEX,
  PROGRESS_PROVIDER_VALUES,
  NOT_AVAILABLE,
  NON_LOCALISED_GUEST,
  LOCALISED_GUEST,
  NON_LOCALISED_USER,
  LOCALISED_USER,
  SHARED_CONSTANTS,
  STORE_END_TIME,
  STORE_OPEN_TIME,
  EMAIL_ADDRESS,
  DATE_FORMATS,
  WEEKDAYNAME,
  ASAP,
  EMAIL_FORMAT_REGEX,
  ALL_TOPICS,
  ASAP_TIMING,
  NULL,
  TENANT_DELIVERY_ADDRESS_MAPPING,
  STORE_NOT_ALIVE,
  ANALYTICS_SHARED_CONSTANTS,
  COUPON_IMAGE_PATH,
  COUPON_IMAGE_NAME,
  CAUTION_NOTICE_URL,
  TIME_BASED_CATEGORY_MENU_NAME,
  ADD_DONATION_PLACEHOLDER,
  NEED_HELP_CONSTANTS,
  days_data,
  HOLIDAYS,
} from 'common/constants/SharedConstants';
import {
  formatDateTime,
  getCurrentDateTime,
  getTimeDifferenceInSeconds,
  getFormatFromUnix,
  parseInvalidDate,
  parseInvalidDateAndFormat,
  dateIsBetween,
  currentDateTimeInFormat,
  getFormattedTimeWithTimeZone,
  getUTCEpochTime,
  subtractMin,
  getDayFromTime,
  subtractMinuteDate,
  getDateTimeWithTimeZone,
  isTomorrowDate,
  checkIsToday,
  getDayFromDate,
  getEpochTime,
} from './dayJsUtils';
import ImageComponent from 'atoms/Image';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { STORES, FORM } from 'organisms/SearchStore/AU/Config/searchStore.config';
import { RESTRICTED_BAD_WORDS } from 'common/constants/restrictionWords';
import { qrCodeScanTimeAction, createBasketsAction } from '@kfc-global/react-shared/redux/Actions/CartAction';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { checkIfCateringPage } from './CateringPLPUtils';
import AuthManager from '@kfc-global/react-shared/auth/AuthManager';
import { getBulkStoreStatus, getStoreDetails } from '@kfc-global/react-shared/redux/Actions/StoresAction';
import SEARCH_STORE_CONSTANTS from 'organisms/SearchStore/AU/Constants/SearchStoreConstants';
import { setLocalStorage } from 'organisms/SearchStore/AU/Presentation/DispositionModuleUtils/DispositionModuleUtils';
import { setUserParams } from '@kfc-global/react-shared/redux/Actions/AppStateAction';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import inRange from 'lodash/inRange';
import findIndex from 'lodash/findIndex';
import update from 'lodash/update';
import { getTimeInMillisec, getOpenLaterStatus } from 'organisms/ScheduleOrder/ScheduleOrderUtils/ScheduleOrderUtils';
import { isGiftCardPurchase, isAddHopePurchase, isGetGiftCardDetails } from './orderStatusUtils';
const { HH_mm, HH_MM, YYYY_MM_DD, M_D_YYYY_h_mm_ss_A } = DATE_FORMATS;
const { PHONE_NUM } = FOOTER_CONSTANT;
const { RightArrowIcon, BucketIcon } = IMAGE_PATH;
const {
  RETAIN_ADDRESS,
  SEARCHED_LOCATIONS,
  DEFAULT_LANGUAGE,
  DROPOFF_ADDRESS,
  DISPOSITION_TYPE,
  DISPOSITION_NEW,
  CURRENT_STORE_INFO_STORAGE,
  STORE_INFO,
  DISPOSITION_OLD,
  DATE_TIME_ORDER,
  SAVED_TIME_ORDER,
  CONFIG_SEARCH_SCREEN,
  FORMATTED_DELIVERY_ADDRESS_FILTERBY,
} = LOCAL_STORAGE_KEYS;

const screenDims = [375, 540, 720, 960, 1140];
const bucket = ['xs', 'sm', 'md', 'lg', 'xl'];
let master = {
  categories: [],
  products: [],
  items: [],
  modgrps: [],
  modifiers: [],
};
const {
  CURRENT_STORE_INFO,
  SET_DISPOSITION,
  SEARCH_ORDER_DATA,
  SCHEDULE_ORDER_DATA,
  DISPOSITION_METHOD,
  USER_LOCALIZED,
  SET_FORCERELOAD,
  SCHEDULE_ORDER_TIME_DATA,
  SCHEDULE_ORDER_TIME_PRESERVE_TIME,
} = START_ORDER_CONSTANTS;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunction = () => {
  /* */
};

export const getTranslationLanguage = params => {
  const userDefaultLang = localStorage.getItem(LOCAL_STORAGE_KEYS?.USER_LANGUAGE);
  const { defaultLanguage, language = null } = params || {};
  let rnaLang = null;
  if (isCurrentPageRedirectFromRNA(window.location)) {
    const queryParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    rnaLang = queryParams?.language ? queryParams.language : language;
  }

  return rnaLang || language || getLanguage() || userDefaultLang || defaultLanguage?.key;
};

export const getTranslation = async params => {
  const lang = getTranslationLanguage(params);
  await initI18N(process?.env?.REACT_APP_API_BASE_URL, lang);
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE, lang);
};

export const getHeaderContentData = data => {
  const {
    ORDERSERV,
    DISPOSITION,
    PDP_DISPOSITION,
    PAGE_TYPE_PDP,
    PAGE_TYPE_HOME,
    PAGE_TYPE_CATERING,
    CATERING_DISPOSITION,
  } = HOME_PAGE_CONTAINER_CONSTANTS;
  const dataItems = data[HOME_PAGE_CONTENT_DATAKEY] || [];
  const allItemObj = {};
  for (const item of dataItems) {
    const cloneObj = { ...item.fields };
    delete cloneObj.title;
    const key = item?.sys?.contentType?.sys?.id;
    if (key === ORDERSERV) {
      allItemObj[item.fields.name] = { ...cloneObj };
    } else if (isSupportedTenantCode() && key === DISPOSITION) {
      if (item?.fields?.pageType === PAGE_TYPE_PDP) {
        allItemObj[PDP_DISPOSITION] = { ...cloneObj };
      } else if (item?.fields?.pageType === PAGE_TYPE_CATERING) {
        allItemObj[CATERING_DISPOSITION] = { ...cloneObj };
      } else if (item?.fields?.pageType === PAGE_TYPE_HOME) {
        allItemObj[key] = { ...cloneObj };
      }
    } else {
      allItemObj[key] = { ...cloneObj };
    }
  }
  return allItemObj;
};

/**
 *
 * @param {*} headerData - header content full data
 */
export const getHeaderData = (headerData = {}) => {
  let headerdata = {
    headerSection: [],
    subHeaderSection: [],
  };
  headerdata.headerSection = headerData?.fields?.headerSection || [];
  headerdata.subHeaderSection = headerData?.fields?.subHeaderSection || [];
  return headerdata;
};
export const isUserAgent = navigator.userAgent;

/**
 *
 * @param {*} tenantData - tenant content full data
 */
export const getTenantData = tenantData => {
  if (tenantData[0]?.fields) {
    return { disposition: tenantData[0].fields.disposition };
  } else {
    return {};
  }
};
/**
 *
 * @param {*} TradingHrsData - Trading Hrs data
 */
export const tradingHrsData = param => {
  return param?.reduce((obj, item) => {
    switch (item.dayOfWeek) {
      case DAYS_OBJ.SUNDAY:
        obj.SunOpen = item.availableHours.startTime;
        obj.SunClose = item.availableHours.endTime;
        break;
      case DAYS_OBJ.MONDAY:
        obj.MonOpen = item.availableHours.startTime;
        obj.MonClose = item.availableHours.endTime;
        break;
      case DAYS_OBJ.TUESDAY:
        obj.TueOpen = item.availableHours.startTime;
        obj.TueClose = item.availableHours.endTime;
        break;
      case DAYS_OBJ.WEDNESDAY:
        obj.WedOpen = item.availableHours.startTime;
        obj.WedClose = item.availableHours.endTime;
        break;
      case DAYS_OBJ.THURSDAY:
        obj.ThuOpen = item.availableHours.startTime;
        obj.ThuClose = item.availableHours.endTime;
        break;
      case DAYS_OBJ.FRIDAY:
        obj.FriOpen = item.availableHours.startTime;
        obj.FriClose = item.availableHours.endTime;
        break;
      case DAYS_OBJ.SATURDAY:
        obj.SatOpen = item.availableHours.startTime;
        obj.SatClose = item.availableHours.endTime;
        break;
      default:
        obj = {};
    }
    return obj;
  }, {});
};

export const safeUpperCase = (param = '') => {
  if (param) {
    return param?.toUpperCase();
  }
  return '';
};
export const getTime = time => {
  return getFormatFromUnix(time, 'h:mm A');
};

export const getDate = time => {
  return getFormatFromUnix(time, 'MMM D,YYYY');
};

export const throttle = (func, wait) => {
  let waiting = false;
  return function () {
    if (waiting) {
      return;
    }
    waiting = true;
    setTimeout(() => {
      func.apply(this, arguments);
      waiting = false;
    }, wait);
  };
};

export const getPrivacyData = privacyPolicyContent => {
  const { privacyPolicyResp } = privacyPolicyContent || {};
  const { items } = privacyPolicyResp || {};
  const fieldsList = items?.map(item => item?.fields);

  const headerData = {
    title: fieldsList[0].title,
    effectiveDate: formatDateTime(fieldsList[0].effectiveDate, 'MMMM D, YYYY'),
  };
  const description = fieldsList[0].description;
  const questionList = fieldsList[0].body.map(item => item.fields);
  return { headerData, description, questionList };
};

/* util- function to get 'terms & conditions' data and 'terms of use' data */
export const getTermsOfUseData = termsOfUseContent => {
  if (termsOfUseContent) {
    const headerData = {
      title: termsOfUseContent.title,
      description: documentToPlainTextString(termsOfUseContent.description),
    };
    const questionList = termsOfUseContent.body?.map(item => item?.fields);
    return { headerData, questionList, seoTemplate: termsOfUseContent.sd };
  }
};

/* util- function to get 'protected disclosure' data */
export const getProtectedDisclosureData = protetedDisclosureContent => {
  const { protectedDisclosureResp: { items } = {} } = protetedDisclosureContent || {};
  const fieldsList = items?.map(item => item?.fields);
  const headerDetails = {
    title: fieldsList[0]?.title,
    effectiveDate: formatDateTime(fieldsList[0]?.effectiveDate, DATE_FORMATS.MMMM_D_YYYY),
  };
  const questions = fieldsList[0]?.body?.map(item => item?.fields);
  return { headerDetails, questions };
};
export const getOurAdsData = ourAdsContent => {
  const { ourAdsResp } = ourAdsContent || {};
  const { items } = ourAdsResp || {};
  const fieldsList = items.map(item => item.fields);
  const headerData = {
    title: fieldsList[0].title,
    effectiveDate: formatDateTime(fieldsList[0].effectiveDate, 'MMMM D, YYYY'),
  };
  const description = fieldsList[0].description;
  const questionList = fieldsList[0].body.map(item => item.fields);
  return { headerData, description, questionList };
};

export const getGiftCardData = getGiftCardDataVal => {
  const items = getGiftCardDataVal?.response?.giftCardResp?.items[0]?.fields?.body || [];
  const fieldsList = items.map(item => item.fields);
  const headerData = {
    headline: fieldsList[0]?.headline,
    subHeadline: fieldsList[0]?.subHeadline,
  };
  const desktopImage = fieldsList[1]?.desktopImage.fields.file;
  const mobileImage = fieldsList[1]?.mobileImage.fields.file;
  const giftAmount = {
    title: fieldsList[2]?.shortText,
    list: fieldsList[2]?.listText,
  };
  const termsncondition = {
    data: fieldsList[3]?.longText,
    title: fieldsList[3]?.shortText,
  };
  return { headerData, desktopImage, mobileImage, giftAmount, termsncondition };
};

/* check whether device is mobile or desktop */
export const isMobileDevice = innerWidth =>
  (window && innerWidth < 1024) || (window?.matchMedia('only screen &(max-width:1023px)')).matches;

export const isMobileDeviceOnly = innerWidth =>
  (window && innerWidth < 651) || (window?.matchMedia('only screen &(max-width:650px)')).matches;

export const isTabletDevice = innerWidth =>
  (window && innerWidth > 651 && innerWidth < 1024) || (window?.matchMedia('only screen &(max-width:1023)')).matches;

export const isIpadProDevice = innerWidth =>
  (window && innerWidth < 1031) || (window?.matchMedia('only screen &(max-width:1031px)')).matches;

export const isIpadDevice = () => {
  return (window?.matchMedia(`${IPAD_CHECK_WIDTH}`)).matches;
};

export const displayBucketIcon = isStoreClosedVal => {
  return !isStoreClosedVal && <ImageComponent className='add-to-cart-Icon' srcFile={BucketIcon} />;
};
/**
 *
 * @param {*} object - object to be checked for blank
 */

export const isEmptyObject = object => {
  let notEmpty = false;
  if (object && Object.keys(object)?.length > 0) {
    notEmpty = true;
  }
  return notEmpty;
};

export const isEmptyArray = object => {
  let isEmptyVal = Array.isArray(object);
  if (isEmptyVal) {
    return object.length === 0;
  }
  return isEmptyVal;
};

/**
 *
 * @param {*} footerData - footer content full data
 */
export const getFooterData = (footerData = {}) => footerData?.fields?.footerContent || [];

/**
 * getFooterLinkIcon
 * @param {*} footerData - footer content full link out icon
 */
export const getFooterLinkIcon = (footerData = {}) =>
  getFooterData(footerData)?.[4]?.fields?.links?.[0]?.fields?.icon?.fields?.desktopImage?.fields?.file?.url || '';

/**
 *
 * @param {*} backgroundImageData
 * @returns backgroundImagePath
 */
export const getBackgroundImagePath = (backgroundImageData = {}) => backgroundImageData?.fields?.file?.url || '';
/**
 *
 * @param {*} content
 * @param {*} width
 * @param {*} height
 * @returns mainImagePath
 */
export const getMainImagePath = (content = {}, width = null, height = null) => {
  let imageBaseURL = content?.image?.mainImage;
  if (height && width) {
    return getUrlImagePath(imageBaseURL, width, height);
  } else {
    return imageBaseURL;
  }
};

/**
 *
 * @param {*} imageBaseURL
 * @param {*} width
 * @param {*} height
 * @returns mainImagePath
 */
export const getUrlImagePath = (imageBaseURL, width, height) => {
  return `${imageBaseURL}?h=${height}&w=${width}&fit=fill&fm=webp`;
};

/**
 *
 * @param {*} val
 * @param {*} prate
 * @returns percentage
 */
export const getPercentage = (val, prate) => {
  return val ? Math.round(val * (prate / 100)) : 0;
};
/**
 *
 * @param {*} path
 * @param {*} width
 * @param {*} height
 * @returns backgroundImageSrc
 */
export const getBackgroundImageSrc = (path, width, height) => {
  return getTernaryValue(
    path && width,
    { backgroundImage: `url(${path}?h=${height}&w=${width}&fit=fill&fm=webp)` },
    {},
  );
};

export const getFaqData = helpReducerContent => {
  let faqData = {
    faqContent: [],
    faqButtonText: '',
  };
  let contactData = {
    callButtonText: '',
    mailButtonText: '',
  };

  const { content = [] } = helpReducerContent?.helpResponse?.Help?.fields || [];

  let faqContent = content
    .filter(item => {
      // return item.fields.question && item.fields.question; Have Sonar Issue
      return item.fields.question;
    })
    .map(item => {
      return {
        question: item.fields.question,
        answer: item.fields.answer?.content[0].content[0].value,
      };
    });
  let faqButton = content
    .filter(item => {
      return item.fields.buttonText && item.fields.name === 'View All FAQs - Button';
    })
    .map(item => {
      return item.fields.buttonText;
    });

  let callButton = content
    .filter(item => {
      return item.fields.buttonText && item.fields.name === 'FAQ - Call us';
    })
    .map(item => {
      return item.fields.buttonText;
    });

  let contactButton = content
    .filter(item => {
      return item.fields.buttonText && item.fields.name === 'Contact Us button';
    })
    .map(item => {
      return item.fields.buttonText;
    });

  let faqHeadline = content
    .filter(item => {
      // return item.fields.headline && item.fields.headline; Have Sonar Issue
      return item.fields.headline;
    })
    .map(item => {
      return item.fields.headline;
    });

  faqData = {
    faqContent: [...faqContent],
    faqButtonText: faqButton[0],
  };
  contactData = {
    headline: faqHeadline[0],
    callButtonText: callButton[0],
    mailButtonText: contactButton[0],
  };
  return { faqData: faqData, contactData: contactData };
};

const FOOTER_CONTENT_TYPES = ['callToAction', 'callToAction', 'navigationLink', 'logoicon', 'faq'];

export const getFooterFaqData = faqContent => {
  const { ourFaqResp: { items = [] } = {} } = faqContent ?? {};
  const { fields: { body = [], faqTopics = [] } = {} } = items[0] ?? {};

  const ButtonInfo = body?.filter(item => {
    const { sys = {} } = item;
    return FOOTER_CONTENT_TYPES.includes(sys.contentType?.sys?.id || '');
  });

  const FinalData = faqTopics?.map(item => {
    const { sys: { id = '' } = {}, fields: { topics_New = [], title = '', topicContent = [] } = {} } = item;
    const topics = topics_New.reduce((target, topic) => {
      target.push(topic.fields?.topicName || '');
      return target;
    }, []);
    const questions = topicContent?.map(qa => ({
      ...qa.fields,
      Id: qa.sys?.id ?? '',
    }));
    return { Id: id, Title: title, Topics: topics, QuesAns: questions };
  });

  const seoObj = { FAQQuesAns: '' };
  const seoData = [];
  faqTopics?.forEach(topic => {
    const { fields: { topicContent = [] } = {} } = topic;
    topicContent?.forEach(({ fields: { question, answer } = {} }) => {
      seoData.push({
        '@type': 'Question',
        name: question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: documentToPlainTextString(answer),
        },
      });
    });
  });
  seoObj.FAQQuesAns = seoData;

  return { FinalData, seoObj, ButtonInfo };
};
export const getDispositionTypeOnAPIFormat = dispositionType => {
  let serviceType = '';
  switch (dispositionType?.toUpperCase()) {
    case TYPE_DELIVERY:
      serviceType = DELIVERY;
      break;
    case TYPE_TAKEAWAY_TEXT:
    case TYPE_TAKE_WAY:
      serviceType = PICK_UP;
      break;
    case TYPE_DINE_IN:
      serviceType = DINE_IN;
      break;
    case TYPE_CATERING:
      serviceType = CATERING;
      break;
    case TYPE_CHRISTMAS_ORDERING:
      serviceType = CHRISTMAS_ORDERING;
      break;
    default:
      console.error('Unknown disposition', dispositionType);
  }
  return serviceType;
};

export const bufferTimeFn = (param, buffertimepmdelivery, buffertimepmpickup) => {
  let buffertime;
  switch (getDispositionTypeOnAPIFormat(param)) {
    case DELIVERY:
      buffertime = buffertimepmdelivery;
      break;
    case PICK_UP:
      buffertime = buffertimepmpickup;
      break;
    default:
      buffertime = buffertimepmpickup;
  }
  return buffertime;
};

export const getDispositionFomat = dispositionType => {
  let resultText;
  switch (dispositionType.toLowerCase()) {
    case PICK_UP:
      resultText = TAKEAWAY_TEXT;
      break;
    case DINE_IN:
      resultText = dispositionType?.toLowerCase();
      break;
    case DELIVERY:
      resultText = DELIVERY_TEXT;
      break;
    case CHRISTMAS_ORDERING:
      resultText = CHRISTMAS_ORDERING_TEXT;
      break;
    default:
      resultText = '';
      break;
  }
  return resultText;
};

export const getDispositionFomatForSelectContent = dispositionType => {
  let resultText;
  switch (dispositionType?.toUpperCase()) {
    case DISPOSITION_KEYS.PICKUP:
      resultText = translateWithI18Next(TRANSLATE_MAPPING_KEY?.PICKUP);
      break;
    case DISPOSITION_KEYS.DINE_IN:
      resultText = translateWithI18Next(TRANSLATE_MAPPING_KEY?.DINE_IN);
      break;
    case DISPOSITION_KEYS.DELIVERY:
      resultText = translateWithI18Next(TRANSLATE_MAPPING_KEY?.DELIVERY);
      break;
    case DISPOSITION_KEYS.CATERING:
      resultText = translateWithI18Next(TRANSLATE_MAPPING_KEY?.CATERING);
      break;
    case DISPOSITION_KEYS.CHRISTMAS_ORDERING:
      resultText = translateWithI18Next(TRANSLATE_MAPPING_KEY?.CHRISTMAS_ORDERING);
      break;
    default:
      resultText = '';
      break;
  }
  return resultText;
  //no default
};

export const getClosestScreenWidth = input => {
  let screen = screenDims.reduce((a, b) => {
    return Math.abs(b - input) < Math.abs(a - input) ? b : a;
  });
  let index = screenDims.indexOf(screen);
  return bucket[index];
};

export const jsonParsed = str => {
  let parsed;
  try {
    parsed = JSON.parse(str);
  } catch (e) {
    parsed = str;
  }
  return parsed;
};

export const jsontoObjects = obj => {
  return (
    obj?.length &&
    obj.reduce(
      (acc, { key, value }) =>
        (acc = {
          ...acc,
          [key]: value === 'True' ? true : value === 'False' ? false : jsonParsed(value),
        }),
      {},
    )
  );
};

export const getPriceBasedOnShowPriceWithTaxFlag = (
  availability,
  pricewithouttax,
  currencyConversionFactor,
  showPriceWithTax = false,
) => {
  if (showPriceWithTax) {
    return getPrice(availability, currencyConversionFactor);
  } else {
    return getPriceWithoutTax(pricewithouttax, currencyConversionFactor);
  }
};
//Get Store CurrentTime
export const currentTimeOfStore = (timeZone, format = DATE_FORMATS.HHmm) => {
  return timeZone && getFormattedTimeWithTimeZone(timeZone, format);
};
//convert time 12:30 to 1230
export const removeColonFromTime = time => {
  return time?.replace(':', '');
};
//covert time 1230 to 12:30
export const convertTime = time => {
  if (!time) {
    return '00:00';
  }
  if (time.length > 4) {
    return time;
  }
  let formatTime = time;
  if (time.length < 4) {
    formatTime = time.padStart(4, 0);
  }
  return `${formatTime.slice(0, 2)}:${formatTime.slice(2)}`;
};

// If multiple time slots in single day
const getCurrentTimeSlot = (result, storeCurrentTime) => {
  const endTime = result.filter(item =>
    inRange(storeCurrentTime, item?.availableHours?.from, item?.availableHours?.to),
  );
  if (!endTime.length) {
    return result[result.length - 1];
  }
  return endTime && endTime[0];
};

// Get filtered object of current day
const getCurrentDayObj = (param, currentDay, storeCurrentTime) => {
  const result = param.reduce((obj, item, index) => {
    if (item.day === currentDay) {
      obj?.push(item);
    }
    return obj;
  }, []);
  return result?.length > 1 ? getCurrentTimeSlot(result, storeCurrentTime) : result[0];
};

// Remove colon from availableHours
const convertOperatingHrsTime = param => {
  return param?.reduce((obj, item, index) => {
    obj.push({
      uniqueId: index,
      day: item?.day?.toLowerCase(),
      availableHours: {
        from: removeColonFromTime(item?.availableHours?.from),
        to: removeColonFromTime(item?.availableHours?.to),
      },
    });
    return obj;
  }, []);
};
// Bridge for next day if today endTime is 23:59 & tomorrow start is 00:00
export const createBridge = (operatingHrs, currentObj) => {
  const currentDayIndex = findIndex(operatingHrs, currentObj);
  const nextDay = operatingHrs[currentDayIndex + 1];
  if (nextDay?.availableHours?.from === STORE_OPEN_TIME) {
    return nextDay?.availableHours?.to === STORE_END_TIME ? createBridge(operatingHrs, nextDay) : nextDay;
  } else {
    return currentObj;
  }
};
const getHrMin = param => {
  const hour = param?.slice(0, 2);
  const minute = param?.slice(2);
  return { hour, minute };
};
// Get time diff between two DateTime
const getTimeDiff = (storeEndTime, subtractNo, storeCurrentTime) => {
  const { hour, minute } = getHrMin(storeEndTime);
  const { hour: currentStoreHr, minute: currentStoreMin } = getHrMin(storeCurrentTime);
  const lastCheckInTime = subtractMin(hour, minute, subtractNo, DATE_FORMATS.h_mm_A);
  const date1 = getDayFromTime(currentStoreHr, currentStoreMin);
  const date2 = subtractMinuteDate(hour, minute, subtractNo);
  const timeDifference = Math.ceil(date2.diff(date1, DATE_FORMATS.minute, true));
  return { lastCheckInTime, timeDifference };
};
// Shift to next day time slot if store is open midnight
const getNextFeatureStoreTimeSlot = (optHrs, endTime) => {
  const checkIfLastItem = optHrs.find(item => item.uniqueId === endTime.uniqueId);
  if (checkIfLastItem.uniqueId === optHrs[optHrs.length - 1].uniqueId) {
    return optHrs[0];
  } else {
    return optHrs[checkIfLastItem.uniqueId + 1];
  }
};
// Convert Store time format
const convertStoreTimeFormat = param => {
  return parseInvalidDateAndFormat(convertTime(param), DATE_FORMATS.HH_MM, DATE_FORMATS.H_MM_AA);
};
// Any shutdown for sameday take data from unavailability
const unavailabilityData = ({
  storeTimeMsg,
  storeCurrentTime,
  endTime,
  currentDay,
  operatingHrs,
  message,
  storeOffline,
  unavailability,
}) => {
  if (
    unavailability?.length &&
    storeOffline?.From &&
    storeCurrentTime >= storeOffline?.From &&
    storeCurrentTime <= storeOffline?.To &&
    storeOffline?.To < endTime?.availableHours?.to
  ) {
    storeTimeMsg.timingMessageStatus = STORE_CLOSED;
    storeTimeMsg.timingMessageLong = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.CLOSED)} • ${translateWithI18Next(
      TRANSLATE_MAPPING_KEY?.OPENAT,
    )} ${convertStoreTimeFormat(storeOffline?.To)}`;
    storeTimeMsg.timingMessageShort = `${convertStoreTimeFormat(storeOffline?.From)} - ${convertStoreTimeFormat(
      storeOffline?.To,
    )}`;
  } else if (
    storeCurrentTime >= endTime?.availableHours?.to ||
    (storeOffline?.To >= endTime?.availableHours?.to && unavailability?.length)
  ) {
    const lastItem = getNextFeatureStoreTimeSlot(operatingHrs, endTime);
    storeTimeMsg.timingMessageStatus = STORE_CLOSED;
    storeTimeMsg.timingMessageLong = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.CLOSED)} • ${translateWithI18Next(
      TRANSLATE_MAPPING_KEY?.CLOSED_NEXT,
    )} ${convertStoreTimeFormat(lastItem?.availableHours?.from)}`;
    storeTimeMsg.timingMessageShort = `${convertStoreTimeFormat(
      endTime?.availableHours?.from,
    )} - ${convertStoreTimeFormat(lastItem?.availableHours?.to)}`;
  }
  // Multiple day shutdown - show only closed msg
  showClosedMessage(storeOffline, unavailability, storeTimeMsg);
};
// Holiday closed scenario
const holidayClosed = ({
  storeTimeMsg,
  storeCurrentTime,
  endTime,
  currentDay,
  operatingHrs,
  message,
  storeOffline,
  unavailability,
}) => {
  if (!unavailability?.length && message?.includes(HOLIDAY_CLOSED)) {
    storeTimeMsg.timingMessageStatus = STORE_CLOSED;
    storeTimeMsg.timingMessageLong = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.CLOSED)} • ${translateWithI18Next(
      TRANSLATE_MAPPING_KEY?.CLOSED_NEXT,
    )} ${convertStoreTimeFormat(endTime?.availableHours?.from)}`;
    storeTimeMsg.timingMessageShort = `${convertStoreTimeFormat(
      endTime?.availableHours?.from,
    )} - ${convertStoreTimeFormat(endTime?.availableHours?.to)}`;
  }
  if (unavailability?.length && message?.includes(HOLIDAY_CLOSED)) {
    storeTimeMsg.timingMessageStatus = STORE_CLOSED;
    storeTimeMsg.timingMessageLong = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.CLOSED)} • ${translateWithI18Next(
      TRANSLATE_MAPPING_KEY?.OPENAT,
    )} ${convertStoreTimeFormat(storeOffline?.From)}`;
    storeTimeMsg.timingMessageShort = `${convertStoreTimeFormat(storeOffline?.From)} - ${convertStoreTimeFormat(
      storeOffline?.To,
    )}`;
  }
};
// Function if store is closed
export const getStoreClosedTime = ({
  storeTimeMsg,
  storeCurrentTime,
  endTime,
  currentDay,
  operatingHrs,
  message,
  storeOffline,
  unavailability,
}) => {
  // Not within operating hrs & store will open in next time slot for same day
  if (
    (storeCurrentTime > endTime?.availableHours?.from && storeCurrentTime < endTime?.availableHours?.to) ||
    (storeCurrentTime < endTime?.availableHours?.from && endTime?.day === currentDay)
  ) {
    storeTimeMsg.timingMessageStatus = STORE_CLOSED;
    storeTimeMsg.timingMessageLong = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.CLOSED)} • ${translateWithI18Next(
      TRANSLATE_MAPPING_KEY?.OPENAT,
    )} ${convertStoreTimeFormat(endTime?.availableHours?.from)}`;
  }
  // Any shutdown for sameday take data from unavailability
  unavailabilityData({
    storeTimeMsg,
    storeCurrentTime,
    endTime,
    currentDay,
    operatingHrs,
    message,
    storeOffline,
    unavailability,
  });
  // Holiday closed scenario
  holidayClosed({
    storeTimeMsg,
    storeCurrentTime,
    endTime,
    currentDay,
    operatingHrs,
    message,
    storeOffline,
    unavailability,
  });

  // Technical shutdown message
  if (message?.includes(TECHNICAL_SHUTDOWN) || message?.includes(STORE_NOT_ALIVE)) {
    storeTimeMsg.timingMessageStatus = STORE_OPEN;
    storeTimeMsg.timingMessageLong = translateWithI18Next(TRANSLATE_MAPPING_KEY?.TECHNICAL_ERROR);
  }
};
// Store open 24hrs or open until
const open24Hrs = ({ storeTimeMsg, endTime, storeEndTime, everyDay24hrsCase }) => {
  if (endTime?.uniqueId - storeEndTime?.uniqueId > 1 || everyDay24hrsCase) {
    storeTimeMsg.timingMessageStatus = STORE_OPEN;
    storeTimeMsg.timingMessageLong = translateWithI18Next(TRANSLATE_MAPPING_KEY?.OPEN24HR);
    storeTimeMsg.timingMessageShort = translateWithI18Next(TRANSLATE_MAPPING_KEY?.OPEN24HR);
  } else {
    storeTimeMsg.timingMessageStatus = STORE_OPEN;
    storeTimeMsg.timingMessageLong = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.OPEN_NOW)} ${convertStoreTimeFormat(
      endTime?.availableHours?.to,
    )}`;
    storeTimeMsg.timingMessageShort = `${convertStoreTimeFormat(
      storeEndTime?.availableHours?.from,
    )} - ${convertStoreTimeFormat(endTime?.availableHours?.to)}`;
  }
};
// Function if store is open
export const getStoreOpenTime = ({ storeTimeMsg, everyDayCase, storeOpenSlot, endTime, currentDay, storeEndTime }) => {
  // Everyday case
  const everyDay24hrsCase = recursiveAndCheck(
    endTime?.day === currentDay,
    everyDayCase,
    storeEndTime?.availableHours?.to === STORE_END_TIME,
    storeEndTime?.availableHours?.from === STORE_OPEN_TIME,
  );
  // Within operating hrs time slot
  if (storeOpenSlot) {
    storeTimeMsg.timingMessageStatus = STORE_OPEN;
    storeTimeMsg.timingMessageLong = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.OPEN_NOW)} ${convertStoreTimeFormat(
      endTime?.availableHours?.to,
    )}`;
  }
  // Store open 24hrs or open until
  open24Hrs({ storeTimeMsg, endTime, storeEndTime, everyDay24hrsCase });
};
export const getDateFromTimeStamp = param => {
  return param?.split('T')[0]?.slice(-2);
};
export const getTimefromTimeStamp = param => {
  return param?.split('T')[1]?.slice(0, 5);
};
// Open Until or Closed message for store
const storeTimingMsg = ({
  endTime,
  storeCurrentTime,
  operatingHrs,
  currentDay,
  storeEndTime,
  everyDayCase,
  store,
  storeService,
  time,
  exactTime,
}) => {
  const storeOffline = {};
  const unavailability = store?.timings?.scheduled?.unavailibilities;
  const { currentStatus, message } = store?.status || {};
  let storeTimeMsg = {
    timingMessageLong: '',
    timingMessageStatus: null,
    timingMessageShort: `${convertStoreTimeFormat(endTime?.availableHours?.from)} - ${convertStoreTimeFormat(
      endTime?.availableHours?.to,
    )}`,
  };
  const storeOpenSlot =
    storeCurrentTime > endTime?.availableHours?.from && storeCurrentTime < endTime?.availableHours?.to;
  if (unavailability?.length) {
    const fromDate = getDateFromTimeStamp(unavailability[0].from);
    const toDate = getDateFromTimeStamp(unavailability[0].to);
    const fromTime = getTimefromTimeStamp(unavailability[0].from);
    const toTime = getTimefromTimeStamp(unavailability[0].to);

    storeOffline.From = fromDate === toDate ? removeColonFromTime(fromTime) : null;
    storeOffline.To = removeColonFromTime(toTime);
  }
  const closingSoonScenarioTiming = closingSoonScenario(time, everyDayCase, endTime, exactTime, storeCurrentTime);
  if (currentStatus === CLOSED) {
    const storeClosedParams = {
      storeTimeMsg,
      storeCurrentTime,
      endTime,
      currentDay,
      operatingHrs,
      message,
      storeOffline,
      unavailability,
    };
    getStoreClosedTime(storeClosedParams);
  }

  if (currentStatus === OPEN) {
    const storeOpenTimeParams = { storeTimeMsg, everyDayCase, storeOpenSlot, endTime, currentDay, storeEndTime };
    getStoreOpenTime(storeOpenTimeParams);
  }

  if (
    unavailability?.length &&
    storeOffline?.From &&
    message?.includes(HOLIDAY_OPEN) &&
    storeCurrentTime >= storeOffline?.From &&
    storeCurrentTime <= storeOffline?.To
  ) {
    storeTimeMsg.timingMessageStatus = STORE_OPEN;
    storeTimeMsg.timingMessageLong = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.OPEN_NOW)} ${convertStoreTimeFormat(
      storeOffline?.To,
    )}`;
    storeTimeMsg.timingMessageShort = `${convertStoreTimeFormat(storeOffline?.From)} - ${convertStoreTimeFormat(
      storeOffline?.To,
    )}`;
  }

  if (checkByParams(storeService, isDeliveryDisposition(storeService), currentStatus === CLOSED)) {
    storeTimeMsg.timingMessageStatus = STORE_CLOSED;
    storeTimeMsg.timingMessageLong = `${translateWithI18Next(
      TRANSLATE_MAPPING_KEY?.DELIVERY_IS_CURRENTLY_UNAVAILABLE,
    )}`;
    storeTimeMsg.timingMessageShort = `${convertStoreTimeFormat(
      endTime?.availableHours?.from,
    )} - ${convertStoreTimeFormat(endTime?.availableHours?.to)}`;
  }
  if (closingSoonScenarioTiming?.timingFlag) {
    storeTimeMsg.timingMessageStatus = STORE_OPEN;
    storeTimeMsg.timingMessageLong = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.closingSoon)} ${
      closingSoonScenarioTiming.lastCheckInTime
    }`;
  }
  return storeTimeMsg;
};
// Closing Soon scenario - 45min
const closingSoonScenario = (time, everyDayCase, endTime, exactTime, storeCurrentTime) => {
  if (+time === +config(STORE_CLOSING_SOON_MODAL_TIMING) && endTime?.availableHours?.to && storeCurrentTime) {
    const timeDiff = getTimeDiff(endTime?.availableHours?.to, +time, storeCurrentTime);
    const lastCheckIn = getTimeDiff(endTime?.availableHours?.to, +config(STORE_CLOSED_MODAL_TIMING), storeCurrentTime);
    const timingFlag = exactTime
      ? timeDiff?.timeDifference === 0
      : timeDiff?.timeDifference <= 0 && timeDiff?.timeDifference > -15;
    return { timingFlag, lastCheckInTime: lastCheckIn.lastCheckInTime };
  }
};
// Store Closing soon timing
export const checkTimeDuration = (store, time, exactTime, storeMessage, storeService) => {
  if (!store) {
    return;
  }
  let storeEndTime,
    currentDay = '';
  let timingFlag = false;
  let result = { timingFlag };
  const storeCurrentTime = currentTimeOfStore(store?.timeZoneIANA, DATE_FORMATS.HHmm);
  const operatingHrs = convertOperatingHrsTime(store?.timings?.operatingHours);

  if (operatingHrs[0]?.day === EVERYDAY?.toLowerCase()) {
    storeEndTime = operatingHrs[0];
    currentDay = EVERYDAY?.toLowerCase();
  } else {
    currentDay = getFormattedTimeWithTimeZone(store?.timeZoneIANA, WEEKDAYNAME)?.toLowerCase();
    storeEndTime = getCurrentDayObj(operatingHrs, currentDay, storeCurrentTime);
  }
  const endTime =
    storeEndTime?.availableHours?.to === STORE_END_TIME && storeCurrentTime > storeEndTime?.availableHours?.from
      ? createBridge(operatingHrs, storeEndTime)
      : storeEndTime;

  const storeEndTimeUpdated = endTime?.availableHours?.to;
  const everyDayCase = currentDay === EVERYDAY?.toLowerCase();
  const storeTimingMsgParams = {
    endTime,
    storeCurrentTime,
    operatingHrs,
    currentDay,
    storeEndTime,
    everyDayCase,
    store,
    storeService,
    time,
    exactTime,
  };
  const timingMsg = storeTimingMsg(storeTimingMsgParams);

  if (everyDayCase) {
    result = { timingFlag };
  }

  if (storeMessage) {
    return timingMsg;
  }

  if (+time === +config(STORE_CLOSED_MODAL_TIMING) && storeEndTimeUpdated && currentDay === endTime?.day) {
    const timeDiff = getTimeDiff(storeEndTimeUpdated, +time, storeCurrentTime);
    timingFlag = timeDiff?.timeDifference <= 0;
    result = { timingFlag };
  }

  if (+time === +config(STORE_CLOSING_SOON_MODAL_TIMING) && storeEndTimeUpdated && currentDay === endTime?.day) {
    result = closingSoonScenario(time, everyDayCase, endTime, exactTime, storeCurrentTime);
  }
  return result;
};

export const getSurgePrice = (availability, currencyConversionFactor) => {
  const priceValue = calculatePrice(availability.price, currencyConversionFactor);
  return priceValue || 0;
};
// calculate price of item
export const getPrice = (availability, currencyConversionFactor, timeZone = '') => {
  if (!isEmptyObject(availability)) {
    return 0;
  }
  if (timeZone) {
    // RENDEV-8623 Timezone added for late night surge logic
    return getSurgePrice(availability, currencyConversionFactor);
  }
  const { dayOfWeek, availableHours, price } = availability;
  const { startTime, endTime } = availableHours;
  const startingTime = parseInvalidDate(convertTime(startTime.time), DATE_FORMATS.HH_mm);
  const endingTime = parseInvalidDate(convertTime(endTime.time), DATE_FORMATS.HH_mm);
  const storeCurrentTime = dateIsBetween(new Date(), startingTime, endingTime);
  const weekDayName = currentDateTimeInFormat(WEEKDAYNAME);

  const dayUpperCase = dayOfWeek?.day?.toUpperCase();
  const currentDay = dayUpperCase === EVERYDAY || dayUpperCase === weekDayName.toUpperCase();
  const priceValue = calculatePrice(price, currencyConversionFactor);
  return storeCurrentTime && currentDay ? priceValue : 0;
};

export const getPriceWithoutTax = (pricewithouttax, currencyConversionFactor) => {
  if (!pricewithouttax || !currencyConversionFactor) {
    return 0;
  }
  return pricewithouttax / currencyConversionFactor;
};

export const getImageSrc = allImgObj => {
  return (allImgObj?.length && allImgObj[0] && allImgObj[0].value) || '';
};

export const getImageversion = imageVersion => {
  const min_version = imageVersion || 0;
  let version = localStorage.getItem(LOCAL_STORAGE_KEYS?.IMAGE_VERSION);
  if (!version || version < min_version) {
    version = min_version;
    localStorage.setItem(LOCAL_STORAGE_KEYS?.IMAGE_VERSION, min_version);
  }
  return version;
};
export const GetCouponImagePath = imageObject => {
  return String(imageObject).split('/').pop().replace(COUPON_IMAGE_NAME, '');
};
export const getFullImagePath = (
  imageObject = {},
  tenantObject = {},
  isDefault = false,
  ifForGenericMenu = false,
  coupon = false,
) => {
  const { tenantId, imageVersion } = tenantObject;
  const version = getImageversion(imageVersion);
  if (!tenantId) {
    return '';
  }
  const basePath = config(TENANT_IMAGE_BASE_URL);
  let imagePath = config(TENANT_IMAGE_PATH);
  let imageSrc = getImageSrc(imageObject);
  const bucketSize = getClosestScreenWidth(window.innerWidth);
  if (coupon) {
    imagePath = COUPON_IMAGE_PATH;
    imageSrc = GetCouponImagePath(imageObject);
  }
  if (isDefault) {
    return basePath + tenantId + imagePath + DEFAULT_IMAGE_PATH;
  }
  if (!isDefault && imageSrc) {
    let imageConcatPath = ifForGenericMenu ? '/images/categories/' : imagePath + bucketSize + '/';
    return basePath + tenantId + imageConcatPath + imageSrc + '.jpg?ver=' + version;
  }
  return '';
};

export const isEqualDisposition = (oldVal, newVal) => {
  if (oldVal === null) {
    return true;
  }
  let flag = true;
  const oldDate = typeof oldVal.date == 'object' ? oldVal.date : new Date(oldVal.date);
  const newDate = typeof newVal.date == 'object' ? newVal.date : new Date(newVal.date);
  if (oldVal.time !== newVal.time || oldDate.setHours(0, 0, 0, 0) !== newDate.setHours(0, 0, 0, 0)) {
    flag = false;
  }
  return flag;
};
export const getPriceOnAvailability = (availability, date, timeParam) => {
  let time =
    timeParam === ASAP
      ? parseInvalidDateAndFormat(getCurrentDateTime(), DATE_FORMATS.hh_mm, DATE_FORMATS.LT)
      : timeParam;
  const { dayOfWeek, availableHours } = availability[0];
  const { startTime, endTime } = availableHours;
  const startingTime = parseInvalidDate(convertTime(startTime.time), DATE_FORMATS.HH_mm);
  const endingTime = parseInvalidDate(convertTime(endTime.time), DATE_FORMATS.HH_mm);
  const currentT = parseInvalidDate(time, DATE_FORMATS.HH_mm);
  const currentTime = dateIsBetween(currentT, startingTime, endingTime);
  const weekDayName = currentDateTimeInFormat(WEEKDAYNAME);

  const currentDay = dayOfWeek.day === 'EVERYDAY' || dayOfWeek.day === weekDayName.toUpperCase();
  return currentTime && currentDay;
};

export const isEmptyEvery = object => !object || Object.values(object).every(x => x === null || x === '');

const getIsForNowVal = data => (data?.selectedTime === ASAP ? true : false);

export const getFormattedDeliveryAddress = async (data, filterby) => {
  const deliveryAddressMapping = JSON.parse(localStorage.getItem(TENANT_DELIVERY_ADDRESS_MAPPING));
  if (window?.google) {
    const results = await geocodeByAddress(data);
    const latLong = await getLatLng(results[0]);
    const formattedResult = parseAddress(results[0]?.address_components, deliveryAddressMapping, filterby);
    return {
      ...formattedResult,
      location: {
        latitude: latLong.lat.toString(),
        longitude: latLong.lng.toString(),
      },
    };
  } else {
    return {};
  }
};

export const getDeliveryAddressData = (
  service,
  deliveryAddress,
  formattedDeliveryAddressFilterBy = localStorage.getItem(FORMATTED_DELIVERY_ADDRESS_FILTERBY),
) => {
  if (service?.toUpperCase() === DISPOSITION_KEYS.DELIVERY) {
    return getFormattedDeliveryAddress(deliveryAddress, formattedDeliveryAddressFilterBy);
  }
  return {};
};

/**
 * getSelectedDeliveryAddressData - builds deliveryAddress object for cart based on selected address fields
 * @param {Object} selectedAddress - stored in userOrderState scheduleOrderState Obj, contains address field data
 * @return {Object}
 */
export const getSelectedDeliveryAddressData = selectedAddress => {
  const {
    apartmentName,
    chome,
    chomeId,
    cityId,
    cityTown,
    companyName,
    postalCode,
    prefecture,
    roomNumber,
    specialNote,
    streetDetailAddress,
  } = selectedAddress;
  return {
    addressLines: [
      prefecture,
      cityTown,
      chome,
      streetDetailAddress,
      apartmentName,
      roomNumber,
      companyName,
      specialNote,
      cityId,
      chomeId,
    ],
    pinCode: postalCode,
    location: {
      latitude: '',
      longitude: '',
    },
  };
};

export const createBasketData = async (
  data,
  dispositionType,
  id,
  dateTimeObj,
  deliveryAddress = '',
  deliveryAddressLines = '',
  selectedAddress = {},
) => {
  if (!data && !isEmptyObject(data)) {
    return;
  }
  const { customerId } = AuthManager.getInstance();
  const defaultLanguage = JSON.parse(localStorage.getItem(DEFAULT_LANGUAGE));
  const dispositionNew = JSON.parse(localStorage.getItem(DISPOSITION_NEW));
  let selectedDateTimeData = dateTimeObj ? dateTimeObj : dispositionNew;
  let deliveryAddressData = deliveryAddress ? deliveryAddress : dispositionNew?.deliveryAddress;
  const isForNow = getIsForNowVal(selectedDateTimeData);
  const epochFormat = parseInvalidDate(
    formatDateTime(selectedDateTimeData?.date, DATE_FORMATS.YYYY_MM_DD) + ' ' + selectedDateTimeData?.time,
    DATE_FORMATS.YYYY_MM_DD_h_mm_A,
  ).unix();
  const epochDateFormat = `${formatDateTime(
    selectedDateTimeData?.date,
    DATE_FORMATS.YYYY_MM_DD,
  )} ${parseInvalidDateAndFormat(selectedDateTimeData?.time, DATE_FORMATS.H_MM_A_CAPS, DATE_FORMATS.HH_mm)}`;
  const dispositionData = dispositionType || data?.service;
  let obj = {
    storeId: data?.storeId || data.id,
    service: dispositionData,
    channel: ChannelManager.channel,
    requestedDateTime: {
      isForNow: isForNow,
      futureDateTime: isForNow
        ? 0
        : getTernaryValue(
            isCateringDisposition(dispositionData),
            getUTCEpochTime(getDateTimeWithTimeZone(epochDateFormat, data?.summary?.timeZoneIANA)),
            epochFormat,
          ),
    },
    customerId: customerId,
    catalog: data?.summary?.menu?.catalogs[0],
    lang: data?.summary?.name_Culture?.[0]?.lang || data?.addresses?.[0]?.lang || defaultLanguage?.key,
    source: isMobileDevice(window.innerWidth) ? SOURCE_FLAG_MOBILE : SOURCE_FLAG_DESKTOP,
  };
  if (!isEmpty(selectedAddress)) {
    obj.deliveryAddress = getSelectedDeliveryAddressData(selectedAddress);
  } else {
    obj.deliveryAddress = await getDeliveryAddressData(dispositionData, deliveryAddressData);
  }
  return obj;
};

export let getFromLocalStorage = key => {
  let value = localStorage.getItem(key);
  return value ? JSON.parse(value) : undefined;
};

export let getStringFromLocalStorage = key => {
  return localStorage.getItem(key);
};

export let setToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export let isKeyAvailableInLocalStorage = key => key in localStorage;

export const displayToast = count => {
  toast.dark(
    <div className='toast-Container'>
      <p>{count > 1 ? ToastMSG_Items : ToastMSG}</p>
      {!window?.location?.pathname?.includes(CARTROUTE) && (
        <Link to='/cart'>
          <button className='button whiteButton toast-button' onClick={handleClickpopup}>
            <span className='text'> {translateWithI18Next('viewCart')}</span>
            <ImageComponent srcFile={RightArrowIcon} />
          </button>
        </Link>
      )}
    </div>,
    {
      hideProgressBar: true,
      bodyClassName: 'add-to-toast',
      className: 'toast-addToCart',
      position: toast.POSITION.BOTTOM_CENTER,
    },
  );
  setTimeout(() => {
    toast.dismiss();
  }, 3000);
};

const handleClickpopup = () => {
  toast.dismiss();
  // window.open(ROUTE_URL.CART, SELF);
  //RENDEV-15218 Redirecting to cart page through router Link component
};

export const setSearchStoreByAtrribute = () => {
  if (localStorage.getItem(SEARCHED_LOCATIONS)) {
    let locations = JSON.parse(localStorage.getItem(SEARCHED_LOCATIONS));
    let searchedLocation = locations[locations.length - 1];
    localStorage.setItem(RETAIN_ADDRESS, searchedLocation);
  }
};

export const getItemQuantity = (id, cartData) => {
  return cartData?.foodLines?.length ? getItemCountFromFoodLines(id, cartData.foodLines) : {};
};

const getItemCountFromFoodLines = (id, foodLines) => {
  let count = 0,
    matchedItemFoodLineId = '',
    entriesCount = 0,
    itemUnitPrice = 0;
  for (let itemIndex = 0; itemIndex < foodLines?.length; itemIndex++) {
    if (id === foodLines[itemIndex]?.item?.id) {
      entriesCount++;
      let itemMatched = foodLines[itemIndex];
      count += itemMatched.quantity;
      matchedItemFoodLineId = itemMatched.id;
      itemUnitPrice = itemMatched.amount;
    }
  }
  return { count, matchedItemFoodLineId, entriesCount, itemUnitPrice };
};

export const getItemQuantityByFoodLineId = (foodId, cartData) => {
  return cartData?.foodLines?.length ? getItemCountFromFoodLinesByFoodLineId(foodId, cartData.foodLines) : {};
};

const getItemCountFromFoodLinesByFoodLineId = (id, foodLines) => {
  let count = 0;
  for (let itemIndex = 0; itemIndex < foodLines?.length; itemIndex++) {
    if (id === foodLines[itemIndex]?.id) {
      let itemMatched = foodLines[itemIndex];
      count += itemMatched.quantity;
    }
  }
  return { count: count === 0 ? 1 : count };
};

export const getQuantityFromItem = item => {
  let count = 0;
  item.forEach(qnt => {
    count += qnt?.quantity;
  });
  return count;
};

// /
//  * Method to set analytics items in the data layer object for change of page
//  */
export const setAnalyticsDataPageLoad = async ({
  collectionPoint = 'NA',
  disposition = 'NA',
  loggedIn = false,
  postLocalisation = false,
  storeID = 'NA',
  UIDNO = 'NA',
  language = 'NA',
  userType = 'NA',
  postCode = 'NA',
}) => {
  await AnalyticsService.registerEvent(
    events?.customDimension?.name,
    {
      '%event%': 'setAttributes',
      '%collectionPoint%': collectionPoint,
      '%disposition%': disposition,
      '%loggedIn%': loggedIn,
      '%postLocalisation%': postLocalisation,
      '%storeID%': storeID,
      '%userID%': UIDNO,
      '%platform%': `'web'`,
      '%language%': language,
      '%userType%': userType,
      '%postCode%': postCode,
    },
    getDataLayer,
  );
};

/*
 * Function to pass the user parmeters and the analytics data
 */
export const userParameterObject = userParameters => {
  if (!userParameters.CollectionPoint) {
    return;
  }
  setAnalyticsDataPageLoad({
    collectionPoint: userParameters.CollectionPoint,
    disposition: userParameters.DispositionType,
    loggedIn: userParameters.loggedIn,
    postLocalisation: userParameters.localized,
    storeID: userParameters.StoreID,
    UIDNO: userParameters.loggedIn ? userParameters.UserId : '',
    language: userParameters.Language,
    userType: userParameters.UserType,
    postCode: userParameters.PostCode,
  });
};

export const base64DecodeToJson = base64EncodedStr => {
  try {
    const decodedString = atob(base64EncodedStr);
    return JSON.parse(decodedString);
  } catch (e) {
    console.warn('invalid base64 encoded string');
  }
};

export const SyncModifierGroups = (customModGrps, modgrps) => {
  return customModGrps?.map(i => {
    let findByIndx = modgrps?.find(item => item.id === i.id);
    return {
      ...i,
      dname: findByIndx?.dname,
    };
  });
};
export const getDataLayer = () => {
  return (window.dataLayer = window.dataLayer || []);
};

export const setVirtualPageAnalytics = async virtualPageAnalytics => {
  await AnalyticsService.registerEvent(
    events.virtualPageView?.name,
    {
      '%event%': virtualPageAnalytics.triggeredEvent,
      '%pageUrl%': virtualPageAnalytics.path,
      '%pageTitle%': virtualPageAnalytics.pageHeading,
    },
    getDataLayer,
  );
};

export const goToMenuPage = history => {
  history?.push({
    pathname: '/menu',
  });
};
export const redirectToMenuPageWithState = (history, frompdp) => {
  history?.push({
    pathname: '/menu',
    state: { frompdp },
  });
};

export const openClearCartModal = (
  userOrderStateDispatch,
  modalOpenObj,
  SHOW_CLEAR_CART_ALERT,
  CLEAR_CART_CONFIRM_CANCEL_MODAL,
  confirmCallBack = null,
) => {
  userOrderStateDispatch({
    type: CLEAR_CART_CONFIRM_CANCEL_MODAL,
    value: { modalOpenFrom: modalOpenObj?.flag, modalToOpenOnConfirm: modalOpenObj?.onConfirm, confirmCallBack },
  });
  userOrderStateDispatch({
    type: SHOW_CLEAR_CART_ALERT,
    value: { showCartClearAlert: true },
  });
};
export const getContactKFCData = ({ title = '', body = [], description: desc = {}, sd: seoTemplate = {} }) => {
  const items = body?.map(item => item.fields);
  if (!isEmpty(items)) {
    const desktopStripesImage = items[0]?.desktopImage?.fields?.file?.url;
    const mobileStripesImage = items[0]?.mobileImage?.fields?.file?.url;
    const description = desc?.content[0]?.content[0]?.value;
    const disclaimer = items[1]?.disclaimer?.content[0]?.content[0]?.value;
    const hyperLinkKFCAU = items[1]?.disclaimer?.content[0]?.content[1]?.data?.uri;
    const hyperLinkTitle = items?.[2]?.title;
    const dropdownOptions = items[1].options;
    const dropdownFirstOptionsArray = [];
    const dropdownSecondOptionsArray = [];
    const dropdownThirdOptionsArray = [];
    const metaDataForm = [];
    if (dropdownOptions && dropdownOptions.length > 0) {
      dropdownOptions.map(itemone => {
        if (itemone?.Parent && itemone?.Parent === NULL && itemone?.IsActive === 1) {
          return dropdownFirstOptionsArray.push(itemone);
        }
        return itemone;
      });
      dropdownOptions.map(itemtwo => {
        if (itemtwo?.Parent && itemtwo?.Parent !== NULL && itemtwo?.IsActive === 1 && itemtwo?.OptionNo === 2) {
          return dropdownSecondOptionsArray.push(itemtwo);
        }
        return itemtwo;
      });
      dropdownOptions.map(itemthree => {
        if (itemthree?.Parent && itemthree?.Parent !== NULL && itemthree?.IsActive === 1 && itemthree?.OptionNo === 3) {
          return dropdownThirdOptionsArray.push(itemthree);
        }
        return itemthree;
      });
      dropdownOptions.map(metaitem => {
        if (
          metaitem?.Parent &&
          metaitem?.Parent !== NULL &&
          metaitem?.IsActive === 1 &&
          metaitem?.FormsMetadata !== null
        ) {
          return metaDataForm.push(metaitem);
        }
        return metaitem;
      });
    }
    const bodyFormData = {
      firstDropdown: dropdownFirstOptionsArray,
      secondDropdown: dropdownSecondOptionsArray,
      thirdDropdown: dropdownThirdOptionsArray,
      metaDataFields: metaDataForm,
    };
    const headerFormData = {
      headLine: title,
      descriptionLine: description,
      disclaimerSection: disclaimer,
      hyperLinkTitle: hyperLinkTitle,
      hyperLinkSection: hyperLinkKFCAU,
      desktopImage: desktopStripesImage,
      mobileImage: mobileStripesImage,
    };
    return { headerFormData, bodyFormData, seoTemplate };
  }
};
/** Splitting Items in @headerData and @bodyFormData */
export const getContactUsData = ({ body = [], sd: seoTemplate = {} }) => {
  const items = body?.map(item => item.fields);

  if (!isEmpty(items)) {
    let faqLink = items.find(item => item.linkText && item.linkText.toUpperCase() === FAQ);
    let cautionData = items.filter(item => item.internalurl === CAUTION_NOTICE_URL);

    const headerData = {
      headline: items[1]?.headline,
      subHeadline: items[1]?.subHeadline,
      callUsButtonData: {
        ...items[2],
        icon: items[2]?.image?.fields?.file?.url,
      },
      faqButtonData: faqLink,
      cautionData: cautionData[0],
    };

    let bodyFormData;
    if (!isEmpty(faqLink) || !isEmpty(cautionData)) {
      bodyFormData = {
        headline: items[4]?.headline,
        topicOptions: [{ ...items[5], contents: items[7]?.content?.map(e => e.fields) }, { ...items[6] }],
      };
    } else {
      bodyFormData = {
        headline: items[3]?.headline,
        topicOptions: [{ ...items[4], contents: items[6]?.content?.map(e => e.fields) }, { ...items[5] }],
      };
    }

    return { headerData, bodyFormData, seoTemplate };
  }
};

export const getFAQContent = (helpSectionContactDetails, faqSectionDetails) => {
  if (faqSectionDetails?.length) {
    const facSectionExist = getAndCheck(helpSectionContactDetails, helpSectionContactDetails?.length);
    faqSectionDetails.title = getTernaryValue(facSectionExist, faqSectionDetails?.[0]?.[FIELDS]?.[TITLE], '');
    faqSectionDetails.description = getTernaryValue(
      facSectionExist,
      faqSectionDetails?.[0]?.[FIELDS]?.[DESCRIPTION],
      '',
    );

    const faqTopics = faqSectionDetails
      ? faqSectionDetails?.[0]?.[FIELDS]?.[CONTENT]?.filter(
          obj =>
            obj?.fields?.title &&
            (obj?.fields?.title?.indexOf(FAQTOPICS) !== -1 || obj?.fields?.title?.indexOf(ALL_TOPICS) !== -1),
        )
      : '';
    faqSectionDetails.topics = faqTopics ? faqTopics?.[0]?.[FIELDS]?.[TOPICS] : [];
    const viewAllTopics = faqSectionDetails
      ? faqSectionDetails?.[0]?.[FIELDS]?.[CONTENT]?.filter(
          obj => obj?.fields?.title && obj?.fields?.title?.indexOf(VIEWALLFAQ) !== -1,
        )
      : '';
    faqSectionDetails.btnText = viewAllTopics ? viewAllTopics?.[0]?.[FIELDS]?.[LINKTEXT] : '';
    faqSectionDetails.linkUrl = viewAllTopics ? viewAllTopics?.[0]?.[FIELDS]?.[INTERNALURL] : '';
  }
};

export const getHelpData = getHelpContent => {
  let bannerTitle = '';
  let helpSectionHeadline;
  let helpSectionNearestKFC;
  let helpSectionCallDetails;
  let helpSectionContactDetails;
  let faqSectionDetails;
  let surveyDetails;

  const content = getHelpContent?.Help?.fields || [];

  if (content?.title === GETHELP) {
    bannerTitle = content.title;
    helpSectionHeadline = content?.body.filter(obj => obj?.fields?.title && obj.fields?.title?.indexOf(GETHELP) !== -1);
    helpSectionHeadline = helpSectionHeadline ? helpSectionHeadline?.[0]?.[FIELDS]?.[HEADLINE] : '';
    helpSectionNearestKFC = content?.body?.filter(
      obj => obj?.fields?.name && obj.fields?.name?.indexOf(NEARESTKFC) !== -1,
    );
    helpSectionNearestKFC = helpSectionNearestKFC ? helpSectionNearestKFC?.[0]?.[FIELDS]?.[NAME] : '';
    helpSectionCallDetails = content?.body?.filter(
      obj => obj.fields?.description && obj.fields?.description?.indexOf(CALLUS) !== -1,
    );
    const helpSectionCallExist = getAndCheck(helpSectionCallDetails, helpSectionCallDetails?.length);
    helpSectionCallDetails.title = getTernaryValue(
      helpSectionCallExist,
      helpSectionCallDetails?.[0]?.[FIELDS]?.[TITLE],
      '',
    );
    helpSectionCallDetails.description = getTernaryValue(
      helpSectionCallExist,
      helpSectionCallDetails?.[0]?.[FIELDS]?.[DESCRIPTION],
      '',
    );
    helpSectionCallDetails.contactNo = getTernaryValue(
      helpSectionCallExist,
      helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.[PHNUMBER],
      '',
    );
    helpSectionCallDetails.buttonText = getTernaryValue(
      helpSectionCallExist,
      helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.[BUTTONTEXT],
      '',
    );
    helpSectionCallDetails.icon = getTernaryValue(
      helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.[IMAGE]?.[FIELDS]?.[FILE]?.[URL]
        : '',
    );
    helpSectionContactDetails = content?.body.filter(
      obj => obj.fields?.title && obj.fields?.title?.indexOf(CONTACTUS) !== -1,
    );
    const helpSectionContactExist = getAndCheck(helpSectionContactDetails, helpSectionContactDetails?.length);
    helpSectionContactDetails.title = getTernaryValue(
      helpSectionContactExist,
      helpSectionContactDetails[0][FIELDS][TITLE],
      '',
    );
    helpSectionContactDetails.description = getTernaryValue(
      helpSectionContactDetails,
      helpSectionContactDetails[0][FIELDS][DESCRIPTION],
      '',
    );
    helpSectionContactDetails.linkText = getTernaryValue(
      helpSectionContactExist,
      helpSectionContactDetails[0][FIELDS][CONTENT][0][FIELDS][LINKTEXT],
      '',
    );
    helpSectionContactDetails.linkUrl = getTernaryValue(
      helpSectionContactExist,
      helpSectionContactDetails[0][FIELDS][CONTENT][0][FIELDS][INTERNALURL],
      '',
    );
    helpSectionContactDetails.mobileImage = getTernaryValue(
      helpSectionContactExist,
      helpSectionContactDetails[0][FIELDS][CONTENT][0][FIELDS][ICON][FIELDS][MOBILEIMG][FIELDS][FILE][URL],
      '',
    );
    helpSectionContactDetails.desktopImage = getTernaryValue(
      helpSectionContactExist,
      helpSectionContactDetails[0][FIELDS][CONTENT][0][FIELDS][ICON][FIELDS][DESKTOPIMG][FIELDS][FILE][URL],
      '',
    );
    faqSectionDetails = content?.body.filter(obj => obj.fields?.title && obj.fields?.title?.indexOf(FAQ) !== -1);
    getFAQContent(helpSectionContactDetails, faqSectionDetails);
    surveyDetails = content?.body?.filter(
      obj => obj?.fields?.title && obj.fields?.title?.indexOf(SHAREEXPERIENCE) !== -1,
    );
    const surveyLengthExist = getAndCheck(surveyDetails, surveyDetails?.length);
    surveyDetails.title = getTernaryValue(surveyLengthExist, surveyDetails?.[0]?.[FIELDS]?.[TITLE], '');
    surveyDetails.description = getTernaryValue(surveyLengthExist, surveyDetails?.[0]?.[FIELDS]?.[DESCRIPTION], '');
    surveyDetails.linkUrl = getTernaryValue(
      surveyLengthExist,
      surveyDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.[URL],
      '',
    );
    surveyDetails.btnText = getTernaryValue(
      surveyLengthExist,
      surveyDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.[BUTTONTEXT],
      '',
    );
    return {
      bannerTitle,
      helpSectionHeadline,
      helpSectionNearestKFC,
      helpSectionCallDetails,
      helpSectionContactDetails,
      faqSectionDetails,
      surveyDetails,
    };
  } else {
    return {};
  }
};

export const getAccountMsgCount = param => {
  return param?.filter(x => x.viewed === false)?.length;
};

export const getHelpDataContent = getHelpContent => {
  if (isValidObject(getHelpContent)) {
    let bannerTitle = '';
    let helpSectionHeadline;
    let helpSectionCallDetails;
    let helpSectionContactDetails;
    let faqSectionDetails;
    let callsupportcontent;

    const content = getHelpContent?.Help?.fields || [];

    if (content.title === 'SupportHelp') {
      bannerTitle = content.title;
      helpSectionHeadline = content?.body.filter(
        obj => obj?.fields?.title && obj.fields?.title?.indexOf('Help - Headline') !== -1,
      );
      helpSectionHeadline = helpSectionHeadline ? helpSectionHeadline?.[0]?.[FIELDS]?.[HEADLINE] : '';
      helpSectionCallDetails = content?.body?.filter(
        obj => obj.fields?.title && obj.fields?.title?.indexOf('HelpNow') !== -1,
      );
      const helpSectionCallExist = getAndCheck(helpSectionCallDetails, helpSectionCallDetails?.length);
      helpSectionCallDetails.title = getTernaryValue(
        helpSectionCallExist,
        helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.['shortText'],
        '',
      );
      helpSectionCallDetails.val1 = helpSectionCallExist
        ? helpSectionCallDetails[0][FIELDS][CONTENT][0][FIELDS]['richText'][CONTENT][0][CONTENT][0]['value']
        : '';

      helpSectionCallDetails.val2 = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.['richText']?.[CONTENT]?.[0]?.[
            CONTENT
          ]?.[1]?.['value']
        : '';
      helpSectionCallDetails.val3 = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.['richText']?.[CONTENT]?.[0]?.[
            CONTENT
          ]?.[2]?.['value']
        : '';
      helpSectionCallDetails.skededal1 = helpSectionCallExist
        ? helpSectionCallDetails[0][FIELDS]?.[CONTENT]?.[4]?.[FIELDS]['richText'][CONTENT][0][CONTENT][0]['value']
        : '';

      helpSectionCallDetails.skededal2 = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[4]?.[FIELDS]?.['richText']?.[CONTENT]?.[0]?.[
            CONTENT
          ]?.[1]?.['value']
        : '';
      helpSectionCallDetails.skededal3 = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[4]?.[FIELDS]?.['richText']?.[CONTENT]?.[0]?.[
            CONTENT
          ]?.[2]?.['value']
        : '';
      helpSectionCallDetails.uberDirect1 = helpSectionCallExist
        ? helpSectionCallDetails[0][FIELDS]?.[CONTENT]?.[2]?.[FIELDS]['richText'][CONTENT][0][CONTENT][0]['value']
        : '';

      helpSectionCallDetails.uberDirect2 = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[2]?.[FIELDS]?.['richText']?.[CONTENT]?.[0]?.[
            CONTENT
          ]?.[1]?.['value']
        : '';
      helpSectionCallDetails.uberDirect3 = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[2]?.[FIELDS]?.['richText']?.[CONTENT]?.[0]?.[
            CONTENT
          ]?.[2]?.['value']
        : '';
      helpSectionCallDetails.kfcGenericMsg1 = helpSectionCallExist
        ? helpSectionCallDetails[0][FIELDS][CONTENT][0][FIELDS]['richText'][CONTENT][0][CONTENT][0]['value']
        : '';

      helpSectionCallDetails.kfcGenericMsg2 = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.['richText']?.[CONTENT]?.[0]?.[
            CONTENT
          ]?.[1]?.['value']
        : '';
      helpSectionCallDetails.kfcGenericMsg3 = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.['richText']?.[CONTENT]?.[0]?.[
            CONTENT
          ]?.[2]?.['value']
        : '';

      helpSectionCallDetails.contactNo = getTernaryValue(
        helpSectionCallExist,
        helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[PHNUMBER],
        '',
      );
      helpSectionCallDetails.skedadelContactNo = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[5]?.[FIELDS]?.[PHNUMBER]
        : '';

      helpSectionCallDetails.uberDirectContactNo = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[3]?.[FIELDS]?.[PHNUMBER]
        : '';

      helpSectionCallDetails.kfcGenericContactNo = helpSectionCallExist
        ? helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[PHNUMBER]
        : '';

      helpSectionCallDetails.buttonText = getTernaryValue(
        helpSectionCallExist,
        helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[BUTTONTEXT],
        '',
      );
      helpSectionCallDetails.icon = getTernaryValue(
        helpSectionCallExist,
        helpSectionCallDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[IMAGE]?.[FIELDS]?.[FILE]?.[URL],
        '',
      );
      callsupportcontent = content?.body?.filter(
        obj => obj.fields?.title && obj.fields?.title?.indexOf(NEED_HELP_CONSTANTS.CALL_SUPPORT_CONTENT) !== -1,
      );
      helpSectionContactDetails = content?.body.filter(
        obj => obj.fields?.title && obj.fields?.title?.indexOf(NEED_HELP_CONSTANTS.GET_BACK) !== -1,
      );
      const helpSectionContactExist = getAndCheck(helpSectionContactDetails, helpSectionContactDetails?.length);
      helpSectionContactDetails.title = getTernaryValue(
        helpSectionContactExist,
        helpSectionContactDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.[HEADLINE],
        '',
      );
      helpSectionContactDetails.description = getTernaryValue(
        helpSectionContactDetails,
        helpSectionContactDetails?.[0]?.[FIELDS]?.[CONTENT]?.[0]?.[FIELDS]?.['subHeadline'],
        '',
      );
      helpSectionContactDetails.buttonText = getTernaryValue(
        helpSectionContactExist,
        helpSectionContactDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[BUTTONTEXT],
        '',
      );
      helpSectionContactDetails.linkText = getTernaryValue(
        helpSectionContactExist,
        helpSectionContactDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[LINKTEXT],
        '',
      );
      helpSectionContactDetails.emailAddress = getTernaryValue(
        helpSectionContactDetails,
        helpSectionContactDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[EMAIL_ADDRESS],
        '',
      );
      helpSectionContactDetails.url = getTernaryValue(
        helpSectionContactDetails,
        helpSectionContactDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[URL],
        '',
      );
      helpSectionContactDetails.internalURL = getTernaryValue(
        helpSectionContactDetails,
        helpSectionContactDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[INTERNALURL],
        '',
      );
      helpSectionContactDetails.icon = getTernaryValue(
        helpSectionContactExist,
        helpSectionContactDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[IMAGE]?.[FIELDS]?.[FILE]?.[URL],
        '',
      );
      helpSectionContactDetails.desktopIcon = getTernaryValue(
        helpSectionContactExist,
        helpSectionContactDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[ICON]?.[FIELDS]?.[DESKTOPIMG]?.[FIELDS]?.[
          FILE
        ]?.[URL],
        '',
      );
      helpSectionContactDetails.mobileIcon = getTernaryValue(
        helpSectionContactExist,
        helpSectionContactDetails?.[0]?.[FIELDS]?.[CONTENT]?.[1]?.[FIELDS]?.[ICON]?.[FIELDS]?.[MOBILEIMG]?.[FIELDS]?.[
          FILE
        ]?.[URL],
        '',
      );
      faqSectionDetails = content?.body.filter(obj => obj.fields?.title && obj.fields?.title?.indexOf(FAQ) !== -1);
      getFAQContent(helpSectionContactDetails, faqSectionDetails);
      return {
        bannerTitle,
        helpSectionHeadline,
        helpSectionCallDetails,
        helpSectionContactDetails,
        faqSectionDetails,
        callsupportcontent,
      };
    }
  }
  return {};
};

export const getValueOrnull = param => {
  return param || null;
};

export const getValueOrDefaultAlternativeObject = (param, defaultAlternative) => {
  return param || defaultAlternative;
};

export const getValueOrDefaultObject = (param = {}) => {
  return param || {};
};
export const getLength = data => data && data?.length;

export const getValueOrDefaultZero = (param = {}) => {
  return param || 0;
};

export const getEmptyObjectforNull = param => {
  if (param === null) {
    return {};
  } else {
    return param;
  }
};

export const getValueOrEmptyString = (param = '') => {
  return param || '';
};

export const getValueOrEmptyStringWithComma = (param = '') => {
  return (param && `, ${param}`) || '';
};

export const getValueOrDefaultArray = (param = []) => {
  return param || [];
};

export const getTernaryValue = (isTrue, param1, param2) => {
  return isTrue ? param1 : param2;
};
export const getMandatoryMark = isPostalCodeMandtoryField => {
  return isPostalCodeMandtoryField ? '*' : '';
};

export const getAndCheck = (param1, param2) => {
  return param1 && param2;
};

export const getAndCheckIfAllPass = (param1, param2, param3) => {
  return param1 && param2 && param3;
};

export const checkByParams = (...args) => {
  return args.reduce((prev, current) => prev && current, true);
};

export const getAndCheckIfOneTrue = (param1, param2) => {
  return param1 || param2;
};

export const getCheckIfAnyOnePass = (param1, param2, param3) => {
  return param1 || param2 || param3;
};

export const ParseJsonSafely = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    // Return a default object, or null based on use case.
    return null;
  }
};

/**
 * Method to get full addresses of the store
 * @{addresses} - object - full addresses details
 */
export const getFullAddressDetails = addresses => {
  if (isEmptyObject(addresses)) {
    const { addressLines, city, state, country, pinCode } = addresses;
    const addressLine = addressLines?.filter(e => e).join(', ');
    const pinCodeTxt = getValueOrEmptyStringWithComma(pinCode);
    const cityTxt = getValueOrEmptyStringWithComma(city);
    const stateTxt = getValueOrEmptyStringWithComma(state);
    const countryTxt = getValueOrEmptyStringWithComma(country);
    return `${addressLine}${cityTxt}${stateTxt}${pinCodeTxt}${countryTxt}`;
  }
  return null;
};

export const isInDevMode = () => {
  let envDev = config(APP_DEVELOPMENT);
  let isDeveloper = false;
  if (envDev === 'true') {
    isDeveloper = true;
  }
  return isDeveloper;
};

export const formatPhoneNumber = (phoneNumber, pattern, splitPattern, maxLengthPhoneNumber = 10) => {
  if (phoneNumber?.length === maxLengthPhoneNumber) {
    return phoneNumber.replace(pattern, splitPattern);
  }
};

export const getGreetingAccordingToTime = () => {
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    return translateWithI18Next(TRANSLATE_MAPPING_KEY?.MORNING);
  } else if (currentHour >= 12 && currentHour < 17) {
    return translateWithI18Next(TRANSLATE_MAPPING_KEY?.AFTER_NOON);
  }
  return translateWithI18Next(TRANSLATE_MAPPING_KEY?.EVENING);
};

export const filterNotHiddenProducts = product => {
  const finalProducts = product?.filter(({ isHidden }) => !isHidden);
  return finalProducts?.length;
};
export const filterNotHiddenItem = item => {
  return item?.filter(({ isHidden }) => !isHidden);
};
export const existsInCategory = (tile, categories) => {
  const flag =
    isEmptyObject(tile) &&
    categories &&
    categories.find(({ name, isHidden }) => !isHidden && name.trim() === tile.name.trim());
  return flag ? tile.name.trim() : '';
};

export const existsInSubCategory = (tile, categories) => {
  const flag =
    isEmptyObject(tile) &&
    categories &&
    categories.find(({ name, isHidden }) => !isHidden && name.trim() === tile.name.trim());
  return flag ? tile.id : '';
};

export const getTotalprice = (itemPrice, modgrps, quantity, currencyConversionFactor) => {
  let delta_price = 0;
  if (modgrps.length > 0) {
    modgrps.forEach(modG => {
      if (modG.modifiers && modG.modifiers.length > 0) {
        modG.modifiers.forEach(modF => {
          delta_price += modF.unitPrice;
        });
      }
    });
  }
  return ((itemPrice * currencyConversionFactor + delta_price) * quantity) / currencyConversionFactor;
};

export const getAddHopeItem = (items, tenantAddHopeFlag, currencyConversionFactor, flag) => {
  return specialItems(items, tenantAddHopeFlag, currencyConversionFactor, flag);
};

export const getCarryBagItem = (items, tenantCarryBagFlag, currencyConversionFactor, flag) => {
  return specialItems(items, tenantCarryBagFlag, currencyConversionFactor, flag);
};

export const specialItems = (items, tenantFlag, currencyConversionFactor, flag) => {
  const { CART_SUMMARY_CARD: { SEARCH_IN_MENU = '' } = {} } = CART_PAGE_CONSTANTS;
  let itemFound;
  if (flag === SEARCH_IN_MENU) {
    itemFound = items?.[tenantFlag];
    return itemFound
      ? {
          id: itemFound.id,
          price: getPrice(itemFound.availability[0], currencyConversionFactor),
        }
      : {};
  } else {
    itemFound = items?.find(({ item }) => item.name === tenantFlag);
    return itemFound ? { id: itemFound.id } : {};
  }
};

export const getAddHopeId = (foodLines, addHope, currencyConversionFactor, flag = SHARED_CONSTANTS?.SEARCH_IN_CART) => {
  const addHopeDataExists = foodLines?.length ? getAddHopeItem(foodLines, addHope, currencyConversionFactor, flag) : {};
  return getValueOrEmptyString(addHopeDataExists?.id);
};

export const getAddHopeDataPrice = (foodLines, addHope, currencySymbol, currencyConversionFactor) => {
  const addHopeId = getAddHopeId(foodLines, addHope, currencyConversionFactor);
  const lineItem = addHopeId ? foodLines?.find(i => i.id === addHopeId) : { amount: 0 };
  return addHopeId ? displayPrice(lineItem?.amount, { currencySymbol, currencyConversionFactor }) : undefined;
};

export const getCarryBagDataExistsId = (
  foodLines,
  carryBag,
  currencyConversionFactor,
  flag = SHARED_CONSTANTS?.SEARCH_IN_CART,
) => {
  const carryBagDataExists = foodLines?.length
    ? getCarryBagItem(foodLines, carryBag, currencyConversionFactor, flag)
    : {};
  return getValueOrEmptyString(carryBagDataExists?.id);
};

export const getCarryBagDataPrice = (foodLines, carryBag, currencySymbol, currencyConversionFactor) => {
  const addCBId = getCarryBagDataExistsId(foodLines, carryBag, currencyConversionFactor);
  const lineItem = addCBId ? foodLines?.find(i => i.id === addCBId) : { amount: 0 };
  return addCBId ? displayPrice(lineItem?.amount, { currencySymbol, currencyConversionFactor }) : undefined;
};

//Method to check if object is undefined or null

export const isValidValue = value => value !== null && value !== undefined && value !== '';

export const isValidObject = value =>
  value !== null &&
  value !== undefined &&
  value !== '' &&
  typeof value === 'object' &&
  !Array.isArray(value) &&
  Object?.keys(value).length > 0;

export const isEqualArray = (array1, array2) =>
  array1 && array2 && Array.isArray(array1) && Array.isArray(array2) && array1?.every(val => array2?.includes(val));

let AnalyticPhoneNUmValidationCounter = 0;
export const updatePhoneNum = (userPhone, inputValue, setEnableButton) => {
  let phoneNumCheck = { ...userPhone };
  let PhoneNumErrorMsg = phoneNumCheck.errorMessage;
  phoneNumCheck.value = inputValue;
  if (!inputValue) {
    phoneNumCheck.error = true;
    phoneNumCheck.errorMessage = translateWithI18Next('phonenumberEmptyHelpText');
    setEnableButton(true);
  } else if (inputValue.length !== 10 || isNaN(inputValue) || !/^\d*$/.test(inputValue)) {
    phoneNumCheck.error = true;
    phoneNumCheck.errorMessage = translateWithI18Next('invalidPhoneNumberErrText');
    setEnableButton(true);
  } else {
    phoneNumCheck.error = false;
    phoneNumCheck.errorMessage = '';
    AnalyticPhoneNUmValidationCounter = 0;
  }
  if (PhoneNumErrorMsg !== phoneNumCheck.errorMessage) {
    AnalyticPhoneNUmValidationCounter = 0;
  }
  if (phoneNumCheck.error && AnalyticPhoneNUmValidationCounter === 0) {
    AnalyticPhoneNUmValidationCounter = 1;
    getEmbeddedErrorMessageAnalytic('OrderLookup', phoneNumCheck.errorMessage);
  }
  return phoneNumCheck;
};
let AnalyticOrderIdValidationCounter = 0;
export const updateorderId = (orderNumber, inputValue, setEnableButton) => {
  let OrderObj = { ...orderNumber };
  let OrderIdErrorMsg = OrderObj.errorMessage;
  OrderObj.value = inputValue;
  if (!inputValue) {
    OrderObj.error = true;
    OrderObj.errorMessage = translateWithI18Next('enterYourOrderNo');
    setEnableButton(true);
  } else if (inputValue.length < 2 || inputValue.trim() === '') {
    OrderObj.error = true;
    OrderObj.errorMessage = translateWithI18Next('invalidOrderId');
  } else {
    OrderObj.error = false;
    OrderObj.errorMessage = '';
    AnalyticOrderIdValidationCounter = 0;
  }
  if (OrderIdErrorMsg !== OrderObj.errorMessage) {
    AnalyticOrderIdValidationCounter = 0;
  }
  if (OrderObj.error && AnalyticOrderIdValidationCounter === 0) {
    AnalyticOrderIdValidationCounter = 1;
    getEmbeddedErrorMessageAnalytic('OrderLookup', OrderObj.errorMessage);
  }
  return OrderObj;
};
export const toastifyFn = (message, defaultValue, className) => {
  toast.dark(
    <div data-testid='validate-toast' className='img-text-div'>
      <span className='text'>{message || defaultValue}</span>
    </div>,
    {
      bodyClassName: 'sign-in-toast',
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_CENTER,
      className: className ? className : '',
    },
  );
};
export const toastifyFnWithImage = (message, imageTemplate, className, toAutoClose = true) => {
  toast.dark(
    <div className='img-text-div'>
      {imageTemplate} <span className='text'>{message}</span>
    </div>,
    {
      bodyClassName: 'sign-in-toast',
      className: className ? className : '',
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: toAutoClose,
    },
  );
};
export const cartFailureDueToAmount = (validationResult, validationObj) => {
  const { amount } = validationResult;
  const { currencyConversionFactor, currencySymbol, store } = validationObj;
  const { maxOrderAmount = 0, minOrderAmount = 0 } =
    store?.summary?.menu ?? ParseJsonSafely(localStorage.getItem(LOCAL_STORAGE_KEYS.PRICE_LIMITS)) ?? {};
  let errMsg = '';
  const { MIN_ORDER_AMOUNT_MESSAGE, MAX_ORDER_AMOUNT_MESSAGE } = CART_PAGE_CONSTANTS;
  if (amount?.errorCode === AMOUNT_VALIDATION_ERROR_CODES.MINIMUM_AMOUNT && minOrderAmount) {
    errMsg = `${translateWithI18Next(MIN_ORDER_AMOUNT_MESSAGE)} ${displayPrice(minOrderAmount, {
      currencySymbol,
      currencyConversionFactor,
    })}`;
  } else if (amount?.errorCode === AMOUNT_VALIDATION_ERROR_CODES.MAXIMUM_AMOUNT && maxOrderAmount) {
    errMsg = `${translateWithI18Next(MAX_ORDER_AMOUNT_MESSAGE)} ${displayPrice(maxOrderAmount, {
      currencySymbol,
      currencyConversionFactor,
    })}`;
  }
  return {
    amountStatus: amount?.status,
    amountMessage: amount.message,
    errMsg,
  };
};
export const ifCheckoutAmountFailure = (validationResult, validationObj) => {
  if (!isEmpty(validationResult)) {
    const validationStatus = get(validationResult, 'overAll.status', false);
    const defaultValidationMessage =
      get(validationResult, 'overAll.message', translateWithI18Next('somethingWentWrong')) ||
      translateWithI18Next('somethingWentWrong');
    if (!validationStatus) {
      let errorMessage = defaultValidationMessage;
      let itemFailureStatus = get(validationResult, ['items', 'validationStatus', 'status'], true);
      if (itemFailureStatus) {
        for (let key in validationResult) {
          if (key === 'amount') {
            let { amountStatus, amountMessage, errMsg } = cartFailureDueToAmount(validationResult, validationObj);
            if (!amountStatus) {
              toastifyFn(`${amountMessage} ${errMsg}`, defaultValidationMessage);
              break;
            }
          } else if (key !== 'overAll') {
            if (!get(validationResult, [key, 'status'], true)) {
              errorMessage = get(validationResult, [key, 'message'], defaultValidationMessage);
              toastifyFn(errorMessage, defaultValidationMessage);
              break;
            } else if (!get(validationResult, [key, 'validationStatus', 'status'], true)) {
              errorMessage = get(validationResult, [key, 'validationStatus', 'message'], defaultValidationMessage);
              toastifyFn(errorMessage, defaultValidationMessage);
              break;
            }
          }
        }
      }
    } else {
      toastifyFn(defaultValidationMessage);
    }
  }
};

export const ifCheckoutItemFailure = validationResult => {
  let itemValidateFlag = true;
  if (isEmptyObject(validationResult)) {
    const { items } = validationResult;
    itemValidateFlag = items?.validationStatus?.status;
  }
  return itemValidateFlag;
};
export const isIos = () => /iPad|iPhone|iPod|iOS/.test(isUserAgent);

export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  let isSafaris = false;
  try {
    isSafaris =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!window['safari'] || window['safari'].pushNotification);
  } catch (err) {
    console.log(err);
  }

  isSafaris =
    isSafaris ||
    (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1 && userAgent.indexOf('version/') !== -1);
  return isSafaris;
};

export const addressSuggestionLengthMatch = (address, MIN_SEARCH_CHARACTER_LIMIT) => {
  return address?.length && address.toString().trim().length > MIN_SEARCH_CHARACTER_LIMIT;
};

export const clearAllAndHardReload = () => {
  localStorage.clear();
  sessionStorage.clear();
  redirectToHomePage();
};

export const isCurrentPageRedirectFromRNA = (location, pageURL = null) =>
  location?.pathname?.includes('/true') && (pageURL?.includes(location?.pathname?.replace?.('/true', '')) ?? true);

export const redirectToHomePage = props => {
  if (!localStorage?.getItem(LOCAL_STORAGE_KEYS?.IMAGE_VERSION)) {
    window?.location?.assign(ROUTE_URL.HOME_PAGE);
  } else {
    props?.history?.push({ pathname: ROUTE_URL.HOME_PAGE });
  }
};

export const redirectToDeleteAccountPage = ({ history }) => {
  history?.push({
    pathname: ROUTE_URL.SIGNOUT,
    state: 'DELETE',
  });
};

export const redirectToFaqPage = (history, item = '') => {
  history?.push({
    pathname: `${ROUTE_URL.FAQ}${item === '' ? '' : '/' + item}`,
  });
};
export const redirectToCARTPage = (props, prevPage = null, isWicodeFinaliseFailed = false) => {
  props?.history?.push({
    pathname: ROUTE_URL.CART,
    prevPage,
    isWicodeFinaliseFailed: isWicodeFinaliseFailed,
  });
};

export const redirectToThankYouPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.THANK_YOU,
  });
};

export const redirectToCheckoutPage = (props, ShowPaymentError = false, isRetryPaymentErrorEnabled = false) => {
  props?.history?.push({
    pathname: ROUTE_URL.CHECKOUT,
    ShowPaymentError: ShowPaymentError,
    isRetryPaymentErrorEnabled: isRetryPaymentErrorEnabled,
  });
};

export const redirectToGiftCardPage = (props, ShowPaymentError = false, ShowPaymentSuccess = false) => {
  props?.history?.push({
    pathname: ROUTE_URL.GIFT_CARD,
    ShowFailureError: ShowPaymentError,
    ShowPaymentSuccess: ShowPaymentSuccess,
  });
};

export const redirectToOrderProcessingPage = props => {
  props?.history?.replace({
    pathname: ROUTE_URL.ORDER_PROCESSING,
  });
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ADD_TO_ORDER);
};

export const redirectToOrderSummaryPage = props => {
  props?.history?.replace({
    pathname: ROUTE_URL.ORDER_SUMMARY,
  });
};

export const redirectToPaymentSuccessPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.PAYMENT_SUCCESS,
  });
};

export const redirectToPaymentFailurePage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.PAYMENT_FAILURE,
    isCardValidateFailedScreen: props?.isCardValidateFailedScreen,
    isAddNewCard: props?.isAddNewCard,
    isFromPaymentFailureForAddNewCard: props?.isAddNewCard,
  });
};

export const redirectToActiveOrdersPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.ACTIVE_ORDERS,
  });
};

export const redirectToTermsAndConditionsPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.TERMS_AND_CONDITIONS,
  });
};

export const redirectToPrivacyPolicyPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.PRIVACY_POLICY,
  });
};

export const redirectToMenuPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.MENU_PAGE,
  });
};

export const redirectToNutritionAllergenLandingPage = history => {
  const updatedPathName = pathNameIncludesTrue(history);
  history?.push({
    pathname: `${ROUTE_URL.NUTRITION_ALLERGEN}${updatedPathName}`,
  });
};
export const redirectToHelpPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.HELP,
  });
};

export const redirectToContactUsPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.CONTACT_US,
  });
};

export const redirectToContactKFCPage = (item, props, location) => {
  const {
    fields: { externalurl, internalurl },
  } = item;
  if (!isEmpty(externalurl)) {
    window?.open(externalurl, '_blank');
  } else {
    props?.history?.push({
      pathname: `${internalurl ?? ROUTE_URL.CONTACT_KFC}${isCurrentPageRedirectFromRNA(location) ? '/true' : ''}`,
    });
  }
};

export const redirectToCateringPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.CATERING_PAGE,
  });
};

export const redirectToAccountPage = (props, selectOption = '') => {
  props?.history?.push({
    pathname: ROUTE_URL.ACCOUNT,
    selectOption,
  });
};
export const redirectToAddHopeThankYouPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.ADD_HOPE_THANK_YOU,
  });
};
export const redirectToAddHopePage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.ADD_HOPE,
  });
};
export const redirectToAddNewCard = props => {
  props?.history?.push({
    pathname: ROUTE_URL.ADD_NEW_CARD,
  });
};
export const redirectToProcessingPage = props => {
  props?.history?.push({
    pathname: ROUTE_URL.PROCESSING,
    props,
  });
};
export const getFilteredItems = (foodLineItems, addHope, carryBag) => {
  let filteredFoodLine = foodLineItems;
  if (foodLineItems?.length > 0) {
    filteredFoodLine = foodLineItems?.filter(
      foodItem => foodItem?.item?.name !== addHope && foodItem?.item?.name !== carryBag,
    );
  }
  return filteredFoodLine;
};

export const getNumberFromStringForAnalytics = param => {
  return param ? +param : 'NA';
};

export const getAnalyticsParamDefaultValue = (event, eventFlag1, eventFlag2, paramVal1, paramVal2) => {
  return event === eventFlag1
    ? getNumberFromStringForAnalytics(paramVal1)
    : (event === eventFlag2 && getNumberFromStringForAnalytics(paramVal2)) || 'NA';
};

/**
 * Loads a javascript into html <head> runtime and calls the callback if successfully loaded
 * @param {string} [url] script url to be loaded
 * @param {string} [scriptId=null] script element id
 * @param {function} [callback=null] callback function to be called on successful script load
 * @return {void}
 * @version 1.0.0 10/14/22 08:39 am
 */
export const loadScript = (url, scriptId = null, callback = null) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback?.();
      }
    };
  } else {
    script.onload = () => callback?.();
  }

  script.src = url;
  if (scriptId) {
    script.id = scriptId;
  }
  document.getElementsByTagName('head')[0].appendChild(script);
};

export const getToOpenMiniPDPModal = (currentItemDetailsMiniPDP, userOrderState) => {
  return (
    currentItemDetailsMiniPDP &&
    userOrderState.cartEditClicked &&
    userOrderState.minipdpData.itemId === currentItemDetailsMiniPDP.id
  );
};

export const isQRModeCheck = qrCodeScanTime => {
  const qrCodeTimeLimitInMinutes = Number(config(Config.QR_CODE_VALID_TIME_MINS));
  const qrCodeTimeLimitInSeconds = qrCodeTimeLimitInMinutes * 60;
  return getTimeDifferenceInSeconds(qrCodeScanTime) < qrCodeTimeLimitInSeconds;
};

export const getBasketPayloadforQROrder = async (
  dispatch,
  isQRmode,
  qrCodeScanTime,
  storeInfo,
  dispositionType,
  customerId,
  dateTimeObjPayload,
) => {
  let basketPayloadData = await createBasketData(storeInfo, dispositionType, customerId, dateTimeObjPayload);
  if (isQRmode && qrCodeScanTime) {
    basketPayloadData.qrOrder = {
      isQROrder: true,
      qrScannedTime: qrCodeScanTime,
    };
  } else {
    dispatch(qrCodeScanTimeAction(''));
  }
  return basketPayloadData;
};

export const dispatchCreateBasketsAction = async (
  dispatch,
  createBasketsAction,
  tenantId,
  userOrderState,
  qrCodeScanTime,
  shouldAddItem,
  addItemList,
) => {
  const dispositionNew = isEmptyEvery(userOrderState?.dispositionNew)
    ? JSON.parse(localStorage.getItem(DISPOSITION_NEW))
    : userOrderState?.dispositionNew;
  const params = {
    storeInfo: dispositionNew?.store,
    dispositionType: getDispositionTypeOnAPIFormat(dispositionNew?.type),
  };
  const isQRmode = isQRModeCheck(qrCodeScanTime);
  const basketPayloadData = await getBasketPayloadforQROrder(
    dispatch,
    isQRmode,
    qrCodeScanTime,
    params.storeInfo,
    params.dispositionType,
  );
  dispatch(createBasketsAction({ data: basketPayloadData, tenantId, shouldAddItem, addItemList }));
};

export const phoneNumber_Check = (inputValue, select) => {
  return select === PHONE_NUM && inputValue.length <= 10;
};

export const availableProductData = (id, availableProducts) => {
  return availableProducts?.filter(products => products.id === id) || {};
};

export const DeliveryAddresslines = deliveryAddress => {
  return (
    <span>
      {deliveryAddress?.addressLines[2] && <span>{deliveryAddress?.addressLines[2]}, </span>}
      {deliveryAddress?.addressLines[1] && <span>{deliveryAddress?.addressLines[1]}, </span>}
      {deliveryAddress?.addressLines[0] && <span>{deliveryAddress?.addressLines[0]}, </span>}
      {deliveryAddress?.state && <span>{deliveryAddress?.state}, </span>}
      {deliveryAddress?.pinCode && <span>{deliveryAddress?.pinCode}, </span>}
      {deliveryAddress?.country && <span>{deliveryAddress?.country}</span>}
    </span>
  );
};

export const getCurrentLocation = path => {
  let pathName = path.charAt(0) === '/' && path.substring(1);
  let upsizeSelectedFrom = '';
  if (path.includes('menu')) {
    upsizeSelectedFrom = 'PLP';
  } else if (path.includes('menu') || path.includes('pdp')) {
    upsizeSelectedFrom = 'PDP';
  } else if (path.includes('account') || path.includes('cart')) {
    upsizeSelectedFrom = pathName.charAt(0).toUpperCase() + pathName.slice(1);
  } else if (path === '/') {
    upsizeSelectedFrom = 'Home';
  }
  return upsizeSelectedFrom;
};

export const manipulateAlreadySearchedLocations = (value, ALREADY_SERACHED_LOCATIONS) => {
  const allSeachedLocations = getValueOrDefaultArray(JSON.parse(localStorage.getItem(ALREADY_SERACHED_LOCATIONS)));
  allSeachedLocations.push(value?.trim());
  let searchedLocation = new Set(allSeachedLocations);
  for (let x of allSeachedLocations) {
    if (searchedLocation.has(x)) {
      searchedLocation.delete(x);
    }
    searchedLocation.add(x);
  }
  localStorage.setItem(ALREADY_SERACHED_LOCATIONS, JSON.stringify([...new Set([...searchedLocation])]));
};
export const enableModal = () => {
  document.querySelector('body').style.overflow = 'hidden';
};
export const disableModal = () => {
  document.querySelector('body').style.overflow = 'auto';
};

export const getModifiers = (mods = []) => {
  return mods.length > 0
    ? mods.map(modItem => modItem?.modifiers?.map(mod => mod?.dname[0]?.value).join(', ')).join(', ')
    : [];
};

export const getEntireCatNavigation = (cat_detail, flatResponse, resultString, catalog, flag = true) => {
  let val = flatResponse?.categories?.find(cat => cat?.id === cat_detail?.parentID);
  let toRenderBaseCategory = flag ? val?.parentID !== catalog : true;
  if (val && toRenderBaseCategory) {
    resultString.push(val.name);
    getEntireCatNavigation(val, flatResponse, resultString, catalog, flag);
  }
  return resultString.slice().reverse().join(' > ');
};

export const setStoreOutOfService = (storeInfo, userOrderStateDispatch, dispositionNew, dispositionType) => {
  let store = getValueOrnull(storeInfo);
  // update store info in localstorage
  localStorage.setItem(CURRENTSTOREINFO, JSON.stringify(store));
  userOrderStateDispatch({
    type: CURRENT_STORE_INFO,
    value: store,
  });

  userOrderStateDispatch({
    type: SET_DISPOSITION,
    value: {
      oldvalue: dispositionNew,
      newValue: {
        type: dispositionType,
        store,
        date: '',
        time: '',
        interval: '',
        deliveryAddress: dispositionType === DELIVERY ? dispositionNew?.deliveryAddress : '',
      },
    },
  });
  localStorage.setItem(DISPOSITIONOLD, JSON.stringify(dispositionNew));
  localStorage.setItem(
    DISPOSITIONNEW,
    JSON.stringify({
      type: dispositionType,
      store,
      date: '',
      time: '',
      interval: '',
      deliveryAddress: dispositionType === DELIVERY ? dispositionNew?.deliveryAddress : '',
    }),
  );
};

export const truncateWords = (text, MAX_LENGTH, isIpad) => {
  if (!isIpad && text?.length > MAX_LENGTH) {
    return text?.substring(0, MAX_LENGTH)?.trim() + '...';
  }
  return text;
};

export const getEmbeddedErrorMessageAnalytic = async (popupOrPageTitle, errorMessage) => {
  await AnalyticsService.registerEvent(
    events.embeddedErrorMessage?.name,
    {
      '%event%': 'embeddedErrorMessage',
      '%popupOrPageTitle%': popupOrPageTitle || 'NA',
      '%errorMessage%': errorMessage || 'NA',
    },
    getDataLayer,
  );
};

export const paymentFailedAnalyticFn = (thatAintRight, failedMsg) => {
  getEmbeddedErrorMessageAnalytic(
    'KFC Payment Failure',
    translateWithI18Next(thatAintRight) + ' ' + translateWithI18Next(failedMsg),
  );
};

export const getMenuReducerItemDetails = (
  menuReducerItemDetails,
  foodItem,
  userOrderState,
  showEdit,
  dispatch,
  showCustomizationModalAction,
) => {
  if (
    menuReducerItemDetails &&
    foodItem?.categorySelected &&
    userOrderState.minipdpData.itemId === menuReducerItemDetails.id &&
    showEdit
  ) {
    dispatch(
      showCustomizationModalAction({
        showProductListingModal: true,
        categorySelected: foodItem?.categorySelected,
      }),
    );
  }
};

export const getTransactionFailedAnalytic = async (errorMessage = 'NA') => {
  await AnalyticsService.registerEvent(
    events?.transactionFailed?.name,
    {
      '%event%': 'checkoutClick',

      '%errorMessage%': errorMessage,
    },
    getDataLayer,
  );
};

export const sanitizeContent = content => {
  let div = document.createElement('div');
  div.innerHTML = content;
  return div.innerText || '';
};

export function getAvailableTimingsBasedOnCurrentDay(operatingHours) {
  const days = DAYS;
  const currentDay = days[new Date().getDay()];
  let availableTimings = getCurrentDayAvailableTimings(operatingHours, currentDay);
  if (operatingHours && availableTimings?.length === 0) {
    availableTimings = operatingHours?.filter(
      time => time?.day?.toLowerCase() === days[days.length - 1]?.toLowerCase(),
    );
  }
  return availableTimings;
}

function getCurrentDayAvailableTimings(operatingHours, currentDay) {
  return operatingHours ? operatingHours?.filter(time => time?.day?.toLowerCase() === currentDay?.toLowerCase()) : [];
}

export function getOpenTime(nextDayAvailableTimings) {
  let result = '';
  if (nextDayAvailableTimings?.length === 1) {
    result = get(nextDayAvailableTimings, availableFrom, false);
  } else if (nextDayAvailableTimings?.length > 1) {
    const openTimings = nextDayAvailableTimings
      ?.map(timing => timing?.availableHours?.from)
      .sort((a, b) => a?.split(':')[0] - b?.split(':')[0]);
    result = openTimings[0];
  }
  return result;
}

export function getCloseTime(availableTimings) {
  let result = '';
  if (availableTimings?.length === 1) {
    result = get(availableTimings, availableTo, false);
  } else if (availableTimings?.length > 1) {
    const closeTimings = availableTimings
      ?.map(timing => timing?.availableHours?.to)
      .sort((a, b) => b?.split(':')[0] - a?.split(':')[0]);
    result = closeTimings[0];
  }
  return result;
}

export const getItemValueElseNA = value => {
  return value || 'NA';
};

export const isSupportedTenantCode = () =>
  [COUNTRY_CODE?.AU, COUNTRY_CODE?.CA, COUNTRY_CODE?.ZA, COUNTRY_CODE?.JP, COUNTRY_CODE?.TH].includes(
    process?.env?.REACT_APP_TENANT_CODE?.toUpperCase(),
  );

export const toggleSwitch = displayFlag => {
  return { display: displayFlag };
};

export const checkAllTrue = arr => arr.every(val => val === true);

export const checkAnyObjValTrue = obj => {
  return isEmptyObject(obj) ? Object.values(obj).some(val => val === true) : false;
};

export const getValueByKeyName = (data, keyName) => {
  let item = data?.find(ele => ele.key === keyName);
  return item ? item.value : 0;
};
/* checking multiple AND conditions */
export const recursiveAndCheck = (...args) => {
  return Boolean(args.reduce((prev, current) => prev && current, true));
};

/* checking multiple OR conditions */
export const recursiveOrCheck = (...args) => {
  return Boolean(args.reduce((prev, current) => prev || current, false));
};
export const getDownloadData = downloadData => {
  if (!isEmptyArray(downloadData)) {
    const downloadObj = {
      headLine: '',
      stripeIcon: '',
      googlePlayIcon: '',
      googlePlayUrl: '',
      appleStoreIcon: '',
      appleStoreUrl: '',
      imageIcon: '',
    };

    const data = downloadData[0];
    downloadObj.headLine = data?.fields?.headline;
    const stripeIconObj = data?.fields?.icon?.filter(item => item?.fields?.title === DOWNLOAD_PAGE_KEYS.STRIPES);
    const stripeIconExist = getAndCheck(stripeIconObj, stripeIconObj?.length);
    const appleStoreIconObj = data?.fields?.icon?.filter(
      item => item?.fields?.title === DOWNLOAD_PAGE_KEYS.DOWNLOAD_APP,
    );
    const appleStoreIconExist = getAndCheck(appleStoreIconObj, appleStoreIconObj?.length);
    const googlePlayIconObj = data?.fields?.icon?.filter(
      item => item?.fields?.title === DOWNLOAD_PAGE_KEYS.GOOGLE_PLAY,
    );
    const googlePlayIconExist = getAndCheck(googlePlayIconObj, googlePlayIconObj?.length);
    const imageIconExist = data?.fields?.image?.fields?.title === DOWNLOAD_PAGE_KEYS.DOWNLOAD_APP_IMAGE;
    downloadObj.stripeIcon = getTernaryValue(
      stripeIconExist,
      stripeIconObj[0]?.[FIELDS]?.[DESKTOPIMG]?.[FIELDS]?.[FILE]?.[URL],
      '',
    );
    downloadObj.appleStoreIcon = getTernaryValue(
      appleStoreIconExist,
      appleStoreIconObj[0]?.[FIELDS]?.[DESKTOPIMG]?.[FIELDS]?.[FILE]?.[URL],
      '',
    );
    downloadObj.appleStoreUrl = getTernaryValue(appleStoreIconExist, appleStoreIconObj[0]?.[FIELDS]?.[URL], '');
    downloadObj.googlePlayIcon = getTernaryValue(
      googlePlayIconExist,
      googlePlayIconObj[0]?.[FIELDS]?.[DESKTOPIMG]?.[FIELDS]?.[FILE]?.[URL],
      '',
    );
    downloadObj.googlePlayUrl = getTernaryValue(googlePlayIconExist, googlePlayIconObj[0]?.[FIELDS]?.[URL], '');
    downloadObj.imageIcon = getTernaryValue(imageIconExist, data?.[FIELDS]?.[IMAGE]?.[FIELDS]?.[FILE]?.[URL], '');
    return downloadObj;
  }
  return {};
};

const DISPOSITION_CSS_CLASSES = {
  [BRAND_PAGE_KEYS.DRIVE_THRU]: 'icondrive',
  [BRAND_PAGE_KEYS.PICK_UP]: 'iconpickup',
  [BRAND_PAGE_KEYS.DELIVERY]: 'icondelivery',
};

export const getBrandData = data => {
  if (!isEmpty(data)) {
    const brandObj = {
      headLine: '',
      dispositions: [],
      cta: undefined,
    };
    const fields = data?.fields;
    brandObj.headLine = fields?.headline;
    brandObj.dispositions = (fields?.icon ?? []).map(icon => ({
      key: icon.sys?.id ?? '',
      category: icon.fields?.category ?? '',
      title: icon.fields?.title ?? '',
      url: icon.fields?.desktopImage?.fields?.file?.url ?? '',
      class: DISPOSITION_CSS_CLASSES[icon.fields?.title ?? ''] ?? '',
    }));

    const brandCta = fields?.button?.fields ?? {};
    if (!isEmpty(brandCta)) {
      brandObj.cta = {
        label: brandCta?.[BUTTONTEXT] ?? '',
        url: brandCta?.[URL] ?? '',
      };
    }
    return brandObj;
  }
  return {};
};

export const getSetLocationBannerData = ({ setLocationBanner }) => {
  const { fields = {} } = (setLocationBanner?.length && setLocationBanner?.[0]) || [];
  const { content = [] } = fields;
  const srcFile = fields?.image?.fields?.file?.url;
  const locationMap = fields?.image?.fields?.title;
  const buttonText = content?.[0]?.fields?.buttonText;
  const textDescription = fields?.description;
  return {
    srcFile,
    locationMap,
    buttonText,
    textDescription,
  };
};

const camelCaseToKebabCase = string =>
  string
    .split('')
    .map((letter, index) => {
      return letter.toUpperCase() === letter ? `${(index !== 0 && '-') || ''}${letter.toLowerCase()}` : letter;
    })
    .join('');

const jsonToCss = obj => {
  let styleString = '';
  for (const [prop, value] of Object.entries(obj)) {
    styleString += `${camelCaseToKebabCase(prop)}: ${value};\n`;
  }
  return styleString;
};

export const createStyleString = styleObj => {
  let styleString = '';
  for (const [key, obj] of Object.entries(styleObj ?? [])) {
    if (key === 'mobile') {
      styleString += `@media (max-width: 1023px) {
         ${jsonToCss(obj)}
       }`;
    } else if (key === 'classNames') {
      styleString += Object.keys(obj).reduce(
        (acc, className) =>
          acc +
          `\n&.${className} {
           ${createStyleString(obj[className])}
       }`,
        '',
      );
    } else {
      styleString += jsonToCss(obj);
    }
  }
  return styleString;
};

export const triggerCartModel = (userOrderStateDispatch, searchedLocation) => {
  userOrderStateDispatch({
    type: SEARCH_ORDER_DATA,
    value: { showSearchStore: true, searchedPlaceNonLocalized: searchedLocation, configSearchScreen: STORES },
  });
};

export const safeLowerCase = (param = '') => {
  if (param) {
    return param?.toLowerCase();
  }
  return '';
};

export const embeddedErrorMessageAnalyticFn = (popupOrPageTitle = '', error = false, errorMessage = '') => {
  if (error) {
    getEmbeddedErrorMessageAnalytic(popupOrPageTitle, errorMessage);
  }
};

export const styleForMaterialTextInput = error => {
  return error ? 'formElement error' : 'formElement';
};

export const callbackFnOnceValidate = (conditions, callBack) => {
  conditions && callBack();
};

export const errorStyleForMaterialTextInput = error => {
  return error ? ' text-danger ' : ' ';
};

export const isValidEmail = email => EMAIL_REGEX.test(email?.toLowerCase());

export const isValidEmailFormat = email => {
  var pattern = EMAIL_FORMAT_REGEX;
  return pattern.test(email?.toLowerCase());
};

export const isValidFullName = fullName => {
  const pattern = FULL_NAME_REGEX;
  return pattern.test(fullName);
};

export const isValueContainsRestrictionWords = value =>
  RESTRICTED_BAD_WORDS.some(word => word?.toLowerCase() === value?.toLowerCase());

export const calculatePrice = (amount, currencyConversionFactor) =>
  Number((amount / currencyConversionFactor).toFixed(2)) || 0;

export const displayPrice = (amount, factors, defaultValue = NOT_AVAILABLE) => {
  const { currencySymbol, currencyConversionFactor } = factors;
  if (currencySymbol && currencyConversionFactor) {
    return `${currencySymbol}${calculatePrice(amount, currencyConversionFactor).toFixed(2)}`;
  }
  return defaultValue;
};

export const getPriceDetails = (price, quantity, currencySymbol, currencyConversionFactor) => {
  return price === 0 ? '' : `(+${displayPrice(price * +quantity, { currencySymbol, currencyConversionFactor })})`;
};

export const getSumOfQuantity = foodLineItems => {
  return foodLineItems?.reduce((acc, food) => +acc + +food?.quantity, 0);
};

export const getSumOfQuantityWithoutHopeBag = (foodLines, addHope, carryBag) => {
  let filteredFoodLineItems = getFilteredItems(foodLines, addHope, carryBag);
  return getSumOfQuantity(filteredFoodLineItems);
};

export const isCateringDisposition = type => safeUpperCase(type) === DISPOSITION_KEYS.CATERING;

export const isDeliveryDisposition = type => safeUpperCase(type) === DISPOSITION_KEYS.DELIVERY;

export const isPickupDisposition = type => safeUpperCase(type) === DISPOSITION_KEYS.PICKUP;

export const isDineInDisposition = type => safeUpperCase(type) === DISPOSITION_KEYS.DINE_IN;

export const isDriveThruDisposition = type => safeUpperCase(type) === DISPOSITION_KEYS.DRIVE_THRU;

export const isPickupOrCateringDisposition = type => isPickupDisposition(type) || isCateringDisposition(type);

export const isDeliveryOrPickupDisposition = type => isDeliveryDisposition(type) || isPickupDisposition(type);

export const isDeliveryOrPickupOrDineInDisposition = type =>
  isDeliveryOrPickupDisposition(type) || isDineInDisposition(type);

export const isDeliveryOrPickupOrDineInOrCateringDisposition = type =>
  isDeliveryOrPickupOrDineInDisposition(type) || isCateringDisposition(type);

export const getValuesforProgressProvider = animationSpeed => {
  switch (animationSpeed) {
    case 5:
      return PROGRESS_PROVIDER_VALUES.forFiveSEC;
    case 4:
      return PROGRESS_PROVIDER_VALUES.forFourSEC;
    default:
      return PROGRESS_PROVIDER_VALUES.forThreeSEC;
  }
};

export const filterOutBlackListedCoupons = coupons => {
  return coupons?.filter(coupon => BLACKLISTED_COUPONS?.some(item => item !== coupon?.code));
};

export const notHiddenProductsCount = product => {
  const finalProducts = product?.filter(({ isHidden }) => !isHidden);
  return finalProducts?.length;
};

export const getUserType = (localised, isLoggedIn) => {
  if (!localised && !isLoggedIn) {
    return NON_LOCALISED_GUEST;
  } else if (localised && !isLoggedIn) {
    return LOCALISED_GUEST;
  } else if (!localised && isLoggedIn) {
    return NON_LOCALISED_USER;
  } else if (localised && isLoggedIn) {
    return LOCALISED_USER;
  }
};

export const UpsellCrossellClassName = () =>
  isSupportedTenantCode() ? 'recommendedCard-container recommendedCard-container-au' : 'recommendedCard-container';

/* manipulate storelist with change in payload for delivery disposition & shouldCheckDropOffAddress coming from tenantReducer*/
export const createPayloadForStatusCall = (storeList, serviceType, deliveryAddressObj, shouldCheckDropOffAddress) => {
  let dropoffAddress;
  /* conditional check to create dropoffAddress based on disposition & tenant */
  if (serviceType?.toUpperCase() === DISPOSITION_KEYS.DELIVERY && shouldCheckDropOffAddress) {
    const dropOffAddressStorage = JSON.parse(localStorage?.getItem(DROPOFF_ADDRESS) ?? '{}');
    let deliveryObj = deliveryAddressObj ?? dropOffAddressStorage;
    const { city = '', state = '', pinCode = '', addressLines = [] } = deliveryObj;
    dropoffAddress = {
      city: city,
      state: state,
      zipCode: pinCode,
      street: (addressLines?.[0] + ' ' + addressLines?.[1])?.trim(),
    };
  }
  return storeList?.map(store => {
    const mandatoryStoreObj = {
      storeId: store?.id || store?.storeId || store?.storeBasic?.id,
      channel: ChannelManager.channel,
      service: serviceType?.toLowerCase(),
    };
    /* if  dropoffAddress is present, then add dropoffAddress to store object
        dropoffAddress is for delivery disposition & for Sopac
    */
    return { ...mandatoryStoreObj, ...(dropoffAddress && { dropoffAddress: dropoffAddress }) };
  });
};

/* set dropOffAddress from localStorage */
export const setDropOffAddress = (dispositionType, results) => {
  const deliveryAddressMapping = JSON.parse(localStorage.getItem(TENANT_DELIVERY_ADDRESS_MAPPING));
  if (dispositionType?.toUpperCase() === DISPOSITION_KEYS.DELIVERY) {
    localStorage.setItem(
      DROPOFF_ADDRESS,
      JSON.stringify(parseAddress(results[0]?.address_components, deliveryAddressMapping)),
    );
  }
};

export const getPDPurl = (category_url, prod_url, path) => {
  let baseURL = checkIfCateringPage(path) ? ROUTE_URL.CATERING_PAGE : ROUTE_URL.MENU_PAGE;
  return `${baseURL}/${category_url?.[0]}/${prod_url}`;
};
export const getSafeString = string => string?.replace(/\s/g, '');

export const getTotalPriceWithoutTaxForCustomItem = (amount, taxAmount, quantity) => {
  let unitPriceWithoutTax = 0;
  unitPriceWithoutTax = (Number(amount) - Number(taxAmount)) / Number(quantity);
  return unitPriceWithoutTax.toFixed(2);
};

export const getPriceWithExtractingJsonHelper = (
  availability,
  additionalProperties,
  currencyConversionFactor,
  showPriceWithTax = false,
) => {
  return getPriceBasedOnShowPriceWithTaxFlag(
    availability,
    jsontoObjects(additionalProperties)?.pricewithouttax,
    currencyConversionFactor,
    showPriceWithTax,
  );
};

export const computePriceBaseOnTax = (price, taxAmount, showPriceWithTax = false) =>
  price && (showPriceWithTax ? Number(price) : Number(price) - Number(taxAmount))?.toFixed(2);

export const getPastMonthYear = number => {
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const monthYear = [];
  const today = new Date();
  const todaysMonth = today.getMonth() + 1;
  let d;
  let month;

  for (let i = number; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), todaysMonth - i, 1);
    month = monthNames[d.getMonth()];
    let year = today.getFullYear();
    if (month > todaysMonth) {
      year = year - 1;
    }
    monthYear.push({ yearMonth: `${year}-${month}` });
  }
  return monthYear;
};

export const getUpdatedString = string => {
  return string?.trim()?.replace(/[^a-zA-Z0-9]$/g, '');
};

export const getDiscountAndCoupon = cartData => {
  const { discountLines = [] } = getValueOrDefaultObject(cartData);
  const len = discountLines && discountLines?.length - 1;
  return discountLines && discountLines[len];
};

export const getCouponDetails = data => {
  const { discountLines = [], discountTotal = '' } = getValueOrDefaultObject(data);
  const discountDetails = [];
  discountLines?.forEach(i => {
    discountDetails.push(i.couponCode);
  });
  const couponCode = discountDetails.join(',');
  return { couponCode, discountTotal };
};

export const getFirstArrayItem = data => {
  return data?.length && data[0];
};

export const getDirectionURL = (storeAddress, findStoreRedirectionUrlRegex) => {
  const address = filterMapMarkerAddress(storeAddress, findStoreRedirectionUrlRegex);
  const baseUrl = config(MAP_DIRECTION_BASE_URL);
  return `${baseUrl}/maps?daddr=${address}`;
};

export const filterMapMarkerAddress = (storeAddress, findStoreRedirectionUrlRegex) => {
  if (findStoreRedirectionUrlRegex) {
    const filteredAddress = storeAddress?.match(findStoreRedirectionUrlRegex)?.join('');
    // filtering unambiguous minus sign("−" unicode is U+2212) instead of hyphen-minus("-" unicode is U+002d) in google address,
    const address = filteredAddress?.includes('1-chōme')
      ? filteredAddress?.split('1-chōme')?.[1]?.split(' ')?.join('+')?.replace('−', '')
      : filteredAddress?.split(' ')?.join('+');
    return address;
  }
  return storeAddress?.split(' ')?.join('+');
};

export const getDistanceBetweenTwoLatLngInKm = params => {
  const { storeLatLng, userLatLng } = params;
  return (
    geoLocation?.calculateDistanceAlgo(storeLatLng?.lat, storeLatLng?.lng, userLatLng?.lat, userLatLng?.lng) / 1000
  );
};

export const getDistanceBetweenTwoLatLngInMeters = params => {
  const { storeLatLng, userLatLng } = params;
  return geoLocation?.calculateDistanceAlgo(storeLatLng?.lat, storeLatLng?.lng, userLatLng?.lat, userLatLng?.lng);
};

export const validateFlagAndCallFunc = (flag, callback, callbackArgs = []) => {
  flag && callback(...callbackArgs);
};

const setDispositionInfo = (
  selectedDispositionValue,
  dateTimeObj,
  currentStoreInfoData,
  userOrderStateDispatch,
  dispatch,
  userParams,
) => {
  userOrderStateDispatch({
    type: SET_DISPOSITION,
    value: {
      oldvalue: selectedDispositionValue,
      newValue: {
        type: selectedDispositionValue,
        store: currentStoreInfoData,
        date: dateTimeObj?.date,
        time: dateTimeObj?.time,
        interval: dateTimeObj?.interval,
        selectedTime: dateTimeObj?.selectedTime,
        deliveryAddress: null,
        deliveryAddressLine: null,
      },
    },
  });
  localStorage.setItem(DISPOSITION_OLD, JSON.stringify(selectedDispositionValue));
  localStorage.setItem(
    DISPOSITION_NEW,
    JSON.stringify({
      type: selectedDispositionValue,
      store: currentStoreInfoData,
      date: dateTimeObj?.date,
      time: dateTimeObj?.time,
      interval: dateTimeObj?.interval,
      selectedTime: dateTimeObj?.selectedTime,
      deliveryAddress: null,
      deliveryAddressLine: null,
    }),
  );

  userOrderStateDispatch({
    type: SCHEDULE_ORDER_TIME_DATA,
    value: { selectedDateTime: dateTimeObj },
  });
  userOrderStateDispatch({
    type: SCHEDULE_ORDER_TIME_PRESERVE_TIME,
    value: { selectContentTime: ASAP_TIMING },
  });
  localStorage.setItem(DATE_TIME_ORDER, JSON.stringify(dateTimeObj));
  localStorage.setItem(SAVED_TIME_ORDER, ASAP_TIMING);
  dispatch(
    setUserParams({
      ...userParams,
      localized: true,
    }),
  );
  userOrderStateDispatch({
    type: USER_LOCALIZED,
    value: { isLocalized: true },
  });
};

const createBasket = async (
  selectedStoreInfo,
  selectedDispositionValue,
  dispatch,
  userOrderStateDispatch,
  tenantId,
  userParams,
) => {
  userOrderStateDispatch({
    type: SCHEDULE_ORDER_DATA,
    value: {
      showScheduleOrderCatering: false,
      selectedStoreInfo,
      changeFlow: true,
    },
  });
  userOrderStateDispatch({
    type: DISPOSITION_METHOD,
    value: { dispositionType: selectedDispositionValue },
  });

  localStorage.setItem(DISPOSITION_TYPE, selectedDispositionValue);
  localStorage.setItem(STORE_INFO, JSON.stringify(selectedStoreInfo));
  localStorage.setItem(CURRENT_STORE_INFO_STORAGE, JSON.stringify(selectedStoreInfo));
  localStorage.setItem(CONFIG_SEARCH_SCREEN, FORM);
  userOrderStateDispatch({
    type: CURRENT_STORE_INFO,
    value: getValueOrDefaultObject(selectedStoreInfo),
  });
  userOrderStateDispatch({ type: SEARCH_ORDER_DATA, value: { showSearchStore: false, configSearchScreen: FORM } });

  userOrderStateDispatch({
    type: SET_FORCERELOAD,
    value: { forceReload: true },
  });

  const dateTimeObj = {
    date: ASAP_TIMING,
    time: ASAP_TIMING,
    selectedTime: ASAP_TIMING,
  };

  setDispositionInfo(
    selectedDispositionValue,
    dateTimeObj,
    selectedStoreInfo,
    userOrderStateDispatch,
    dispatch,
    userParams,
  );
  setSearchStoreByAtrribute();
  setLocalStorage(selectedStoreInfo?.name?.[0]?.value);

  if (selectedStoreInfo?.summary?.status?.currentStatus === SEARCH_STORE_CONSTANTS.STORE_STATUS_OPEN) {
    const data = await createBasketData(selectedStoreInfo, selectedDispositionValue, '', dateTimeObj, null);
    dispatch(createBasketsAction({ data, tenantId }));
  }
};

export const localizeUserWithStoreInfo = ({
  dispatch,
  service,
  storeId,
  tenantId,
  userOrderStateDispatch,
  userParams,
  shouldCheckDropOffAddress,
  history,
}) => {
  dispatch(
    getStoreDetails(
      {
        storeId,
        tenantId,
      },
      selectedStoreDetailsDL => {
        const statusCallPayload = {
          data: createPayloadForStatusCall([{ id: storeId }], service, null, shouldCheckDropOffAddress),
          store: [{ ...selectedStoreDetailsDL, service }],
          tenantId,
        };
        dispatch(
          getBulkStoreStatus(statusCallPayload, response => {
            response.length > 0 &&
            response?.[0]?.summary?.status?.currentStatus === SEARCH_STORE_CONSTANTS.STORE_STATUS_OPEN
              ? createBasket(response[0], service, dispatch, userOrderStateDispatch, tenantId, userParams)
              : userOrderStateDispatch({
                  type: START_ORDER_CONSTANTS.DISPOSITION_DATA,
                  value: { showDisposition: true },
                });
          }),
        );
      },
      () => {
        goToMenuPage(history);
      },
    ),
  );
};
export const redirectToSelectedFAQ = (redirectionPath, redirectURL, showContent = '') => {
  return redirectionPath?.replace('#', '').replace(`${redirectURL}/`, '').replace(/%20/g, ' ').replace(showContent, '');
};

function flattenArray(into, node) {
  if (node == null || (node?.custom && !node?.isAdded)) {
    return into;
  }
  if (Array.isArray(node)) {
    return node.reduce(flattenArray, into);
  }
  into.push(node);
  return flattenArray(into, node?.modGrps || node.modifiers);
}

export const checkModifiersAvailability = (cartItem, exclusionsResponse) => {
  const modifiers = flattenArray([], cartItem?.modGrps);
  return !!exclusionsResponse?.find(
    excl => excl?.itemId === cartItem?.id || modifiers.find(mod => mod?.id === excl?.itemId),
  );
};

export const checkExclusion = (itemId, excludedMenuItems, NA) => {
  return excludedMenuItems?.some(excludedItem => excludedItem?.itemId === itemId);
};
export const filterExcludedItem = (recommendedItems, exclusionsResponse, NA) => {
  recommendedItems?.forEach((recommendedItem, idx) => {
    if (checkExclusion(recommendedItem?.id, exclusionsResponse, NA)) {
      recommendedItems?.splice(idx, 1);
    }
  });
};
//Method to extract specific properties from array
export function extractRestaurantDetails(props) {
  const { name, localAddress, code } = props;
  const { address } = localAddress?.[0];
  const { state, addressLines, country, pinCode, city } = address;
  return { name, state, country, pinCode, city, addressLines, code };
}

//Extracting specific parameters from restaurant list by use of extractRestaurantDetails method
export const contactUsRestaurantList = restaurantList => {
  return restaurantList?.map(item => (item.isActive ? extractRestaurantDetails(item) : {}));
};

//Filtering the restaurant array by matchinh with paramater address
export const filtercontactUsRestaurantList = (restaurantList, address, setRestaurantList) => {
  const addressToNumber = +address;
  const updatedRestaurantList = restaurantList?.filter(restaurant => {
    if (!isNaN(addressToNumber)) {
      return restaurant?.pinCode?.includes(address);
    }
    return (
      restaurant?.state?.toLowerCase()?.includes(address.toLowerCase()) ||
      restaurant?.city?.toLowerCase()?.includes(address.toLowerCase()) ||
      restaurant?.name?.toLowerCase()?.includes(address.toLowerCase()) ||
      restaurant?.country?.toLowerCase()?.includes(address.toLowerCase()) ||
      restaurant?.addressLines?.[0]?.toLowerCase()?.includes(address.toLowerCase())
    );
  });
  const filteredRestaurants = updatedRestaurantList?.slice(0, 50);
  setRestaurantList(filteredRestaurants);
};

export const createNewBasketId = async (dispatch, tenantId, userOrderState, storesWithStatus) => {
  let dispositionNew = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.DISPOSITION_NEW));
  const catalogName = storesWithStatus?.[0]?.summary?.menu?.catalogs?.[0];
  catalogName && update(dispositionNew, 'store.summary.menu.catalogs[0]', () => catalogName);
  const params = {
    storeInfo: dispositionNew?.store,
    dispositionType: getDispositionTypeOnAPIFormat(dispositionNew?.type),
  };
  let obj = await createBasketData(params.storeInfo, params.dispositionType);
  dispatch(createBasketsAction({ data: obj, tenantId }));
};

export const getUpdatedStoreInfo = (storeChanged, currentStoreInfo, currentOrderInfo) => {
  const { service, store: storeData, deliveryAddress } = getValueOrDefaultObject(currentOrderInfo);
  const { storeId } = getValueOrDefaultObject(storeData);
  const {
    summary = {},
    id = '',
    service: SelectedService = '',
    addresses = [],
  } = getValueOrDefaultObject(currentStoreInfo);
  return getTernaryValue(
    storeChanged,
    {
      modifiedStoreId: id,
      modifiedStoreData: { name: summary?.name, storeId: id },
      modifiedService: SelectedService,
      modifiedDeliveryAddress: addresses[0]?.address,
    },
    {
      modifiedStoreId: storeId,
      modifiedStoreData: storeData,
      modifiedService: service,
      modifiedDeliveryAddress: deliveryAddress,
    },
  );
};

export const getUpdatedPayloadInfo = storeDetail => {
  const storeDetails = _first(storeDetail, {});
  const {
    addresses = [],
    summary: {
      name = '',
      menu: {
        catalogs: [catalog],
      },
    },
    id,
    service = PICK_UP,
  } = storeDetails;
  return {
    lang: addresses?.[0]?.lang,
    catalog,
    storeId: id,
    deliveryAddress: getTernaryValue(service === DELIVERY, addresses?.[0]?.address, {}),
    storename: name,
    service,
  };
};

export const checkIfStoreShutDown = storesWithStatus => {
  const storeSummary = storesWithStatus?.[0]?.summary ?? storesWithStatus?.summary ?? {};
  const storeStatus = storeSummary?.status;
  if (
    storeStatus?.currentStatus === CLOSED &&
    [TECHNICAL_SHUTDOWN, STORE_NOT_ALIVE].some(message => storeStatus?.message?.includes(message))
  ) {
    return true;
  }
  return false;
};

const getItemVariantList = itemVariantObject => {
  return itemVariantObject.map(obj => obj.name);
};

export const getItemDetailsfrom = (flatResponse, pdpPayloadData, cartData, currencyConversionFactor) => {
  const { discountTotal } = getCouponDetails(cartData);
  const { foodLines } = cartData;
  const { id: item_id, name: item_name, basePrice: price, quantity: quantityAfterDecrease, modGrps } = pdpPayloadData;
  const itemDetailsArray = foodLines?.filter(itemDetail => itemDetail?.item?.id === item_id);
  const quantityBeforeDecrease = itemDetailsArray?.[0]?.quantity;
  const quantityDecreased = quantityBeforeDecrease - quantityAfterDecrease;
  const item_category = flatResponse?.itemMapById[item_id]?.categoryName;
  const item_list_id = flatResponse?.itemMapById[item_id]?.categoryId;
  const item_variant_results = isEmptyArray(modGrps) ? 'NA' : getItemVariantList(Object.values(modGrps));
  return [
    {
      index: 1,
      item_category: item_category,
      item_name: item_name,
      item_id: item_id,
      item_list_name: item_category,
      item_list_id: item_list_id, //optional
      item_variant: item_variant_results.join(),
      price: calculatePrice(price, currencyConversionFactor),
      discount: calculatePrice(discountTotal, currencyConversionFactor), //optional
      currency: ANALYTICS_SHARED_CONSTANTS.AUD,
      quantity: Math.abs(quantityDecreased),
    },
  ];
};

export const navigateToRestaurantPage = (item, history) => {
  const restaurantName = item?.storeBasic?.dname?.[0]?.value?.split(' ')?.join('-')?.toLowerCase();
  const postCode = item?.storeBasic?.address?.[0]?.address?.pinCode;
  history?.push({
    pathname: `${ROUTE_URL.RESTAURANT}/${restaurantName}/${postCode}`,
    state: { storeId: item?.storeBasic?.id },
  });
};

export const filterMarketingData = (data, currencyCode = 'NA') => {
  const currency = currencyCode;
  const index = 0;
  let itemsArray = [];
  const { analyticsData = {} } = data;
  const {
    item_variant = '',
    item_name = '',
    creative_name = '',
    item_category = '',
    item_id = '',
    location_id = '',
    price = '',
    promotion_id = '',
    item_list_name = '',
    item_list_id = '',
    quantity = '',
    item_brand = '',
    affiliation = '',
    discount = '',
    creative_slot = '',
    coupon = '',
    promotion_name = '',
  } = analyticsData;
  itemsArray.push({
    item_id,
    item_name,
    affiliation,
    coupon,
    creative_name,
    creative_slot,
    currency,
    discount,
    index,
    item_brand,
    item_category,
    item_list_id,
    item_list_name,
    item_variant,
    location_id,
    price,
    promotion_id,
    promotion_name,
    quantity,
  });
  const ecommerceObject = {
    creative_name,
    creative_slot,
    location_id,
    promotion_id,
    promotion_name,
  };
  return {
    ecommerceObject,
    itemsArray,
  };
};

export const filterStoreMapData = (stores, latLongDetails) => {
  return stores?.find(
    store =>
      store?.location?.latitude === latLongDetails?.lat() && store?.location?.longitude === latLongDetails?.lng(),
  );
};
export const isElementInViewport = el => {
  if (!el) {
    return;
  }
  var rect = el.getBoundingClientRect();
  return rect.bottom > 0 && rect.right > 0 && rect.left < window.innerWidth && rect.top < window.innerHeight;
};

export const revertOutOfService = objForRevertOutOfService => {
  const { dispositionNew, userOrderStateDispatch, serviceType, storeSummary, dateTimeObj } = objForRevertOutOfService;
  localStorage.setItem(CURRENTSTOREINFO, JSON.stringify(storeSummary));
  userOrderStateDispatch({
    type: CURRENT_STORE_INFO,
    value: storeSummary,
  });
  const setDispositionNew = {
    type: serviceType,
    store: storeSummary,
    date: dateTimeObj.date,
    time: dateTimeObj.time,
    interval: dateTimeObj.interval,
    selectedTime: dateTimeObj.selectedTime,
    deliveryAddress: dispositionNew?.deliveryAddress,
  };
  userOrderStateDispatch({
    type: SET_DISPOSITION,
    value: {
      oldvalue: dispositionNew,
      newValue: setDispositionNew,
    },
  });
  localStorage.setItem(DISPOSITIONOLD, JSON.stringify(dispositionNew));
  localStorage.setItem(DISPOSITIONNEW, JSON.stringify(setDispositionNew));
};

export const checkForStoreStatus = objForStatusCheck => {
  const {
    currentStoreInfo,
    serviceType,
    shouldCheckDropOffAddress,
    tenantId,
    dispositionNew,
    dispositionOld,
    dispatch,
    userOrderStateDispatch,
    statusCheckFromCart = false,
  } = objForStatusCheck;
  const checkStoreStatusResponse = storeDetails => {
    const storeSummary = storeDetails[0];
    const storeStatus = storeSummary?.summary?.status?.currentStatus;
    const checkForSlots = dispositionNew?.store?.summary?.timings?.scheduled?.periods.length > 0;
    const checkForStatusSlots = storeSummary?.summary?.timings?.scheduled?.periods.length > 0;
    if (
      (storeStatus === CLOSED || !checkForStatusSlots) &&
      dispositionNew?.store?.summary?.status?.currentStatus === OPEN
    ) {
      dispositionNew['time'] = '';
      setStoreOutOfService(storeSummary, userOrderStateDispatch, dispositionNew, serviceType);
    } else if (
      storeStatus === OPEN &&
      checkForStatusSlots &&
      (dispositionNew?.store?.summary?.status?.currentStatus === CLOSED || !checkForSlots)
    ) {
      const dateTimeObj = {
        time: ASAP,
        interval: storeSummary.summary.timings.scheduled.periods[0]?.interval,
        date: dispositionOld?.date ? dispositionOld?.date : new Date(),
        selectedTime: ASAP,
      };
      const objForRevertOutOfService = {
        dispositionNew,
        userOrderStateDispatch,
        serviceType,
        storeSummary,
        dateTimeObj,
      };
      revertOutOfService(objForRevertOutOfService);
    }
    if ((storeStatus === CLOSED || !checkForStatusSlots) && statusCheckFromCart && dispositionNew?.time === '') {
      const storeTime = storeSummary?.summary?.timings?.operatingHours[0]?.availableHours;
      const message = `${translateWithI18Next(CART_PAGE_CONSTANTS.SAVED_YOUR_ITEMS_CART)} ${storeTime?.from}hr. to ${
        storeTime?.to
      }hr.`;
      toastifyFn(message);
    }
  };

  if (currentStoreInfo) {
    dispatch(
      getBulkStoreStatus(
        {
          data: createPayloadForStatusCall([currentStoreInfo], serviceType, null, shouldCheckDropOffAddress),
          tenantId,
          store: [currentStoreInfo],
        },
        res => checkStoreStatusResponse(res),
      ),
    );
  }
};

export const replaceCategoryName = (message, cName) => {
  return translateWithI18Next(message)?.replace(TIME_BASED_CATEGORY_MENU_NAME, cName);
};

export const getDiscountedPrice = cartObj => {
  const { unitPrice, basePrice, cartData, quantity, currencyConversionFactor } = cartObj;
  const { total, discountTotal } = cartData;
  const discountedPrice = total > 0 ? total + quantity * basePrice : unitPrice - discountTotal;
  return calculatePrice(discountedPrice, currencyConversionFactor);
};

// Multiple day shutdown - show only closed msg
export const showClosedMessage = (storeOffline, unavailability, storeTimeMsg) => {
  if (!storeOffline?.From && unavailability?.length) {
    const checkTomorrowDate = isTomorrowDate(unavailability[0]?.to?.split('T')[0]);
    storeTimeMsg.timingMessageStatus = STORE_CLOSED;
    if (checkTomorrowDate) {
      storeTimeMsg.timingMessageLong = `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.CLOSED)} • ${translateWithI18Next(
        TRANSLATE_MAPPING_KEY?.CLOSED_NEXT,
      )} ${convertStoreTimeFormat(storeOffline?.To)}`;
    } else {
      storeTimeMsg.timingMessageLong = translateWithI18Next(TRANSLATE_MAPPING_KEY?.CLOSED);
    }
  }
};

export const getTipSelection = params => {
  const { tipChargeType, tipCharge, currencySymbol, currencyConversionFactor, driverTippingChargeOption } = params;
  switch (tipChargeType) {
    case DRIVER_TIPPING.PERCENTAGE:
    case DRIVER_TIPPING.COMPOSITE:
      return driverTippingChargeOption[0].value.toString() + '%';
    case DRIVER_TIPPING.ABSOLUTE:
      return displayPrice(tipCharge, { currencySymbol, currencyConversionFactor });
    case DRIVER_TIPPING.NOT_NOW:
      return DRIVER_TIPPING.NOT_NOW;
    case DRIVER_TIPPING.OTHERS:
      return DRIVER_TIPPING.OTHERS;
    //no default
  }
};

export const getPlaceHolderText = addHopeFlow => {
  return addHopeFlow ? ADD_DONATION_PLACEHOLDER : translateWithI18Next(TRANSLATE_MAPPING_KEY?.TIP_AMOUNT);
};

export const isFutureTime = store => Boolean(store?.summary?.orderingAttribute?.isFutureTimeOrder);
export const isFutureDate = store => Boolean(store?.summary?.orderingAttribute?.isFutureDateOrder);
export const isAsapOrder = store => Boolean(store?.summary?.orderingAttribute?.isAsapOrder);
export const isToday = scheduleDate => {
  const currentDate = new Date();
  return (
    scheduleDate.getDate() === currentDate.getDate() &&
    scheduleDate.getMonth() === currentDate.getMonth() &&
    scheduleDate.getFullYear() === currentDate.getFullYear()
  );
};

export const getToday = (scheduleDate, timeZone) => {
  const parsedFutureDay = getDayFromDate(scheduleDate, DATE_FORMATS.YYYY_MM_DD);
  const timeStamp = getTimeInMillisec(scheduleDate);
  return checkIsToday(timeStamp, timeZone) ? translateWithI18Next(TRANSLATE_MAPPING_KEY.TODAY) : DAYS[parsedFutureDay];
};

export const validateAddHopeForm = (donationsButtonData, isValid) => {
  return (
    (donationsButtonData?.defaultTipSelected ||
      (!isEmpty(donationsButtonData.customAmount) && parseInt(donationsButtonData.customAmount) > 0)) &&
    isValid
  );
};

export const getClassNameforAddTipButton = params => {
  const { isAddHopeFlow, addDonationBtnHandler, inputFiledValidErrormsg, donationsButtonData, isValid } = params;
  if (!isAddHopeFlow) {
    return addDonationBtnHandler && inputFiledValidErrormsg === '' && donationsButtonData.customAmount !== ''
      ? 'mt-4 drivertip-button'
      : 'mt-4 drivertip-button-disabled';
  } else {
    return validateAddHopeForm(donationsButtonData, isValid)
      ? 'mt-4 drivertip-button'
      : 'mt-4 drivertip-button-disabled';
  }
};
export const checkActiveOrderPage = () => {
  return window?.location?.pathname.includes('active-orders');
};
export const daysOrEverydayCheck = (optHrs, day) => {
  let checkedArray;
  if (optHrs?.[0]?.day === Strings.weekdayInCaps) {
    checkedArray = optHrs;
  } else {
    checkedArray = optHrs?.filter(item => item?.day?.toUpperCase() === days_data[day]?.toUpperCase());
  }
  return checkedArray;
};

/* utils function to club overlapping unavailabilities */
export const handleOverlappingUnavailability = (unavailabilities, timeZone) => {
  const outputArr = [];
  const formatTime = arr =>
    arr?.map(obj => ({
      from: obj?.from,
      to: obj?.to,
      inTimeZoneFrom: obj?.inTimeZoneFrom,
      inTimeZoneTo: obj?.inTimeZoneTo,
    }));

  const epochTimeObj =
    unavailabilities?.length &&
    unavailabilities
      ?.map(item => ({
        from: DayJsUtil.tz(item?.from, timeZone)?.format(HH_mm),
        to: DayJsUtil.tz(item?.to, timeZone)?.format(HH_mm),
        inTimeZoneFrom: item?.from,
        inTimeZoneTo: item?.to,
      }))
      ?.sort((dateA, dateB) => dateA?.from - dateB?.from);
  epochTimeObj?.forEach(timeObj => {
    const outputArrLength = outputArr?.length;
    const indexToCheck = outputArrLength - 1;
    if (outputArrLength) {
      const lastTime = outputArr?.[indexToCheck];
      if (lastTime?.to < timeObj?.from) {
        outputArr.push(timeObj);
      } else {
        outputArr[indexToCheck].to = lastTime?.to >= timeObj?.to ? lastTime?.to : timeObj?.to;
      }
    } else {
      outputArr.push(timeObj);
    }
  });
  return formatTime(outputArr);
};
export const isStoreClosed = storeSummary => Boolean(storeSummary?.status?.currentStatus === CLOSED);
export const getcurrentEpochTime = timeZone => {
  const options = {
    timeZone: timeZone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  if (!timeZone) {
    return;
  }
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formatedFullDate = formatter?.format(new Date());
  const formatedDateTime = formatedFullDate?.split(',').join(' ');
  return getEpochTime(formatedDateTime, M_D_YYYY_h_mm_ss_A);
};
export function checkIfUnvailLessThanOperatingCloseHour(storeSummary) {
  const { timings = {} } = storeSummary || {};
  const { operatingHours: optHrs = [] } = timings;
  const { timeZoneIANA: timeZone = '' } = storeSummary || {};
  const { scheduled = {} } = timings;
  const { unavailibilities = [] } = scheduled;
  const unavailabilitiesLength = unavailibilities?.length;
  const currentEpochTime = getcurrentEpochTime(timeZone);
  const date = scheduled?.date;
  if (isStoreClosed(storeSummary)) {
    if (unavailabilitiesLength > 0) {
      const toTime = handleOverlappingUnavailability(unavailibilities, timeZone)?.[0]?.to;
      const closeStoreTime = daysOrEverydayCheck(optHrs, getDayFromDate(date, YYYY_MM_DD));
      return Boolean(getEpochTime(toTime, HH_MM) < getEpochTime(closeStoreTime[0]?.availableHours?.to, HH_MM));
    } else {
      return getOpenLaterStatus(storeSummary, currentEpochTime, date);
    }
  }

  return true;
}
export const setUpdateCounter = (dispositionNew, cartReducer, calculateTimeDiff, setCounter) => {
  if (checkByParams(dispositionNew, cartReducer?.cartData?.foodLines?.length, calculateTimeDiff?.timingFlag)) {
    setCounter(1);
  }
};

export const isStoreTemproryClosedRescheduleFlag = (cartData, storeSummary) => {
  const { hh_mm } = DATE_FORMATS;
  const { timings = {} } = storeSummary || {};
  const { timeZoneIANA: timeZone = '' } = storeSummary || {};
  const currentEpochTime = getcurrentEpochTime(timeZone);
  const userTimeSlot = cartData?.requestedDateTime?.isForNow
    ? currentEpochTime
    : cartData?.requestedDateTime?.futureDateTime;
  const { scheduled = {} } = timings;
  const { unavailibilities = [], unavailibilityType = '' } = scheduled;
  const unavailabilitiesLength = unavailibilities?.length;
  const date = scheduled?.date;
  if (isStoreClosed(storeSummary)) {
    if (unavailabilitiesLength > 0) {
      const toTime = handleOverlappingUnavailability(unavailibilities, timeZone)?.[0]?.to;
      const fromTime = handleOverlappingUnavailability(unavailibilities, timeZone)?.[0]?.from;
      const unavailabeStartEpochTime = getEpochTime(fromTime, hh_mm);
      const unavailabeEndEpochTime = getEpochTime(toTime, hh_mm);
      return unavailibilityType === HOLIDAYS
        ? true
        : dateIsBetween(userTimeSlot, unavailabeStartEpochTime, unavailabeEndEpochTime);
    } else {
      return getOpenLaterStatus(storeSummary, currentEpochTime, date);
    }
  }
  return true;
};
export const isStoreTemproryClosed = (cartData, storeSummary) => {
  const isStoreTempropryClosedReschedule = isStoreTemproryClosedRescheduleFlag(cartData, storeSummary);
  const checkIfUnvailLessThanOperatingCloseHourFlag = checkIfUnvailLessThanOperatingCloseHour(storeSummary);
  return Boolean(checkIfUnvailLessThanOperatingCloseHourFlag && isStoreTempropryClosedReschedule);
};
export const checkForClickAndCollect = ({
  scheduleTimeCheck,
  enableClickCollectScheduleOrder,
  storeStatus,
  setOrderScheduleModal,
  setShowAlert,
  cartData,
  storeSummary,
  isFutureDateTimeOrder,
}) => {
  if (enableClickCollectScheduleOrder) {
    const isTemproryClosed = isStoreTemproryClosed(cartData, storeSummary);
    const isStoreOpen = Boolean(storeStatus?.currentStatus === OPEN);
    if ((!isStoreOpen && isTemproryClosed && isFutureDateTimeOrder) || scheduleTimeCheck) {
      setOrderScheduleModal(true);
      return true;
    } else if (!isStoreOpen && !isTemproryClosed) {
      setShowAlert(true);
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const getPaymentFailedButtonText = () => {
  if (isAddHopePurchase()) {
    return translateWithI18Next(TRANSLATE_MAPPING_KEY?.BACK_TO_ADD_HOPE);
  } else if (isGiftCardPurchase() || isGetGiftCardDetails()) {
    return translateWithI18Next(TRANSLATE_MAPPING_KEY?.RETURN_TO_GIFT_CARD);
  }
  return translateWithI18Next(TRANSLATE_MAPPING_KEY?.BACK_TO_CART);
};

export const marketingPreferenceText = () => {
  return (
    <div className='preferences-text'>
      <label htmlFor={translateWithI18Next(TRANSLATE_MAPPING_KEY.SMS_MARKETING)}>
        {translateWithI18Next(TRANSLATE_MAPPING_KEY.SMS_MARKETING)}
      </label>
    </div>
  );
};

export const getDispositionNew = userOrderState => {
  return isEmpty(userOrderState?.dispositionNew) || userOrderState?.dispositionNew?.type === ''
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS?.DISPOSITION_NEW))
    : userOrderState?.dispositionNew;
};

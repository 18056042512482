import React from 'react';
import PropTypes from 'prop-types';

/**
 * Alert Component
 */

export const ALERT_TYPE = {
  danger: 'danger',
  info: 'info',
};

export const Alert = ({ children, type, className = '' }) => {
  return <div className={`alert alert-${type} ${className}`}>{children}</div>;
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.keys(ALERT_TYPE)).isRequired,
  className: PropTypes.string,
};

export const NUTRITION_ALLERGEN_CONSTANTS = {
  NUTRITION_ALLERGEN_ACTION_RESPONSE: 'nutritionAllergenDisclaimerReducer.response',
  SOURCE: 'landingPage',
  KJ_TEXT: 'kj',
  SINGLE_ITEM: 'Single Item',
  SINGLE_ITEM_CATEGORY_ID: 'S-1909',
  SINGLE_ITEM_URL: 'single-item',
  SINGLE_ITEM_FIRST_PRODUCT_ID: 'P-00',
  SINGLE_ITEM_SECOND_PRODUCT_ID: 'P-01',
  LONG_DESCRIPTION: 'Long Description',
};

export const CART_PAGE_CONSTANTS = {
  INDULGENT_ADD_ON: {
    HEADER: 'cartRecommendTitle',
    SLIDES_TO_SCROLL: 1,
    SLIDES_TO_SHOW: 3,
    ADD_TO_CART: 'addToCart',
    CONTINUE_TO_MY_CART: 'continueToMyCart',
    ITEM_ADDED_TO_CART: 'itemAddedToOrder',
  },
  GIFTCARD: 'giftcard',
  GIFT_CARD: 'gift card',
  VOUCHER: 'voucher',
  PRICECHANGENOTIFICATION: 'priceChangeNotify',
  OUTOFSTOCKNOTIFICATION: 'outOfStockNotify',
  CART_HEADER: 'myCart',
  CART_SUMMARY_CARD: {
    QUANTITY_TEXT: 'cartItem',
    QUANTITYS_TEXT: 'cartItems',
    SUBTOTAL: 'cartSubTotal',
    CARRY_BAG: 'tickToAddLargeCarryBag',
    ADD_HOPE_HEADER: 'tickToAddHope',
    ADD_HOPE_TEXT: 'addHopeText',
    CHECKOUT: 'cartCheckout',
    TAKEAWAY: 'Pickup',
    RESTAURANT_HANDLING_CHARGE: 'cartIncluTax',
    GST: 'cartGst',
    DONATE: 'donate',
    addHopeText: 'addhope',
    largeCarryBagText: 'largeCarryBag',
    SEARCH_IN_MENU: 'SEARCH_IN_MENU',
    SEARCH_IN_CART: 'SEARCH_IN_CART',
    CART: 'cart',
  },
  FROMCHECKOUT: 'FROMCHECKOUT',
  CART_FAQ_HEADER: 'cartfaqtitle',
  CART_FOOD_LIST_ITEM: {
    EDIT: 'edit',
    REMOVE: 'cartRemove',
    REMOVE_ALL: 'cartRemoveAll',
    ADD_MORE_MENU: 'cartAddMoreMenu',
    ADD_MORE_FOOD: 'cartAddMoreFood', //'Add More Food',
    ITEM_QUANTITY: 1,
    MODIFIER_QUANTITY: 2,
    ACCORDION_MODIFIER_MAX_QUANTITY: 1,
  },
  OFFERS_COUPONS: {
    APPLY_OFFERS: 'cartOffersDeals',
    VIEW_ALL: 'cartViewAll',
  },
  QUANTITY_ACTION: { Increase: 'plus', Decrease: 'minus' },
  TOAST_NOTIFICATION: {
    ITEM_REMOVED: 'cartRemoveLineItemConfirmationtext',
    ADDON_REMOVED: 'addonRemoved',
    COUPON_APPLIED: 'offerAddedToastMsg',
    APPLIED: 'Applied',
  },
  ALERT: {
    REMOVE_ALL_MSG: 'cartRemoveAllConfirmationtext',
    REMOVE_ALL: 'Remove All',
    CANCEL: 'cancel',
  },
  EMPTY_CART: {
    PLACEHOLDER_TEXT: 'yourCartEmpty',
    SUGGESTION_TEXT: 'upSellTitle',
    START_ORDER: 'startOrder',
  },
  RECOMENDATIONS: {
    HEADER: 'upSellTitle',
    SLIDES_TO_SCROLL: 1,
    SLIDES_TO_SHOW: 3,
    ADD_TO_CART: 'addToCart',
  },
  ANALYTICS_EVENTS: {
    EDIT: 'editCartItem',
    REMOVE: 'remove_from_cart',
    ADD_TO_CART: 'add_to_cart',
    REDEEM_OFFER: 'redeemOffer',
  },
  CART_ALERT: 'CART_ALERT',
  SET_ADDED_LOCATION: 'SET_ADDED_LOCATION',
  CART_EXPIRE_TIME_HRS: 24,
  ADD_TO_CART_CLICK: 'ADD_TO_CART_CLICK',
  MIN_ORDER_AMOUNT_MESSAGE: 'minOrderAmountMessage',
  MAX_ORDER_AMOUNT_MESSAGE: 'maxOrderAmountMessage',
  PDP_DATA_FOR_UPDATE_FOODLINE: 'PDP_DATA_FOR_UPDATE_FOODLINE',
  CUSTOMISE_FLAG_CART: 'CUSTOMISE_FLAG_CART',
  REMOVE_ITEM_FLAG: 'REMOVE_ITEM_FLAG',
  APPLIED: 'Applied',
  QUANTITY_MODIFIED: 'quantityModified',
  REMOVE_ALL_QUANTITY: 'removeAllQty',
  SAVED_YOUR_ITEMS_CART: 'savedYourItemsInCart',
  ITEM_LIST_NAME: 'Cart Page',
  APPLIED_COUPON: 'APPLIED',
  EDIT_ITEM: 'EDIT_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  SHOW_PDP_ALERT_POPUP: 'pdpAlertPopup',
  QUANTITY_INCREASE_DECREASE: 'QUANTITY_INCREASE_DECREASE',
  STORE_DISPOSITION_UPDATE: 'STORE_DISPOSITION_UPDATE',
  REMOVE_COUPON: 'REMOVE_COUPON',
};

import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CartAlertFormInsideModel from 'molecules/Alert/Container/AlertFormInsideModel';
import CartAlertForm from 'molecules/Alert/Presentation/Alert/AlertForm';
import { UserOrderContext } from 'context/context';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';
import { isEmptyEvery } from 'common/utilities/utils';
import { fetchMenuAction } from '@kfc-global/react-shared/redux/Actions/MenuAction';
import { cartDataErrorReset } from '@kfc-global/react-shared/redux/Actions/CartAction';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { Config } from 'common/constants/SharedConstants';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';

const OutOfStockCartError = props => {
  const { CartError } = IMAGE_PATH;
  const dispatch = useDispatch();
  const tenantReducer = useSelector(data => data.tenantReducer);
  const { userOrderState, userOrderStateDispatch } = useContext(UserOrderContext);
  const dispositionNew = isEmptyEvery(userOrderState.dispositionNew)
    ? JSON.parse(localStorage.getItem('dispositionNew'))
    : userOrderState?.dispositionNew;
  const { type: dispositionType = '', store } = dispositionNew || {};

  const catalogs =
    (store &&
      store.summary &&
      store.summary.menu &&
      store.summary.menu.catalogs &&
      store.summary.menu.catalogs.length &&
      store.summary.menu.catalogs[0]) ||
    tenantReducer?.genericMenuName ||
    '';

  const PRODUCT_NOT_FOUND_CONSTANTS = {
    CLEAR_ALL_MSG: {
      MSGTXT1: translateWithI18Next('cartError_explore'),
      MSGTXT2: translateWithI18Next('outOfStock'),
      MSGTXT3: translateWithI18Next('cartError_sorryFoodNotAvailable'),
    },
    CONTINUE: translateWithI18Next('continue'),
  };

  const continueHandler = () => {
    userOrderStateDispatch({
      type: START_ORDER_CONSTANTS.SHOW_CART_ERROR_MODAL,
      value: { showCartErrorModal: false },
    });
    dispatch(
      cartDataErrorReset({
        type: START_ORDER_CONSTANTS.CART_DATA_ERROR_RESET,
      }),
    );

    dispatch(
      fetchMenuAction(
        {
          tenantId: tenantReducer?.tenantId,
          catalogName: catalogs,
        },
        { storeId: store.id, channel: Config.VIEWPORT_AGENT_BROWSER, service: dispositionType },
      ),
    );
  };
  return (
    <>
      {userOrderState?.showCartErrorModal === true && (
        <CartAlertFormInsideModel
          handleClose={continueHandler}
          classNames='clear-cart-modal'
          CartAlertForm={
            <CartAlertForm
              splitTitle={PRODUCT_NOT_FOUND_CONSTANTS.CLEAR_ALL_MSG}
              imageSrc={CartError}
              altText={'cart-error'}
              confirmButtonText={'Continue'}
              confirmHandler={continueHandler}
            />
          }
        />
      )}
    </>
  );
};
export default OutOfStockCartError;
